define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/calendar/calendar/layout.hbs',

  'modules/shop.cash-register-retail/collections/employeeCalendarItems',
  'modules/shop.cash-register-retail/collections/employeeCalendar',
  'modules/shop.cash-register-retail/collections/employee',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'upx.modules/CalendarModule/collections/Item',
  'modules/shop.cash-register-retail/collections/calendarItemType',

  'modules/shop.cash-register-retail/models/selectedCustomer',

  'modules/admin/behaviors/loader',

  'modules/common/components/moment',
  'modules/common/components/locale',
  'toastr',

  'modules/shop.cash-register-retail/views/popups/calendarEventPopup',
  'modules/shop.cash-register-retail/views/popups/confirmPopup',

  '@fullcalendar/core',
  '@fullcalendar/daygrid',
  '@fullcalendar/timegrid',
  '@fullcalendar/resource-daygrid',
  '@fullcalendar/resource-timegrid',

  '@fullcalendar/interaction',
  '@fullcalendar/list',
  '@fullcalendar/moment',
  '@fullcalendar/bootstrap',

  '@fullcalendar/core/locales-all',

  '@fullcalendar/core/main.css',
  '@fullcalendar/bootstrap/main.css',
  '@fullcalendar/daygrid/main.css',
  '@fullcalendar/timegrid/main.css',
  '@fullcalendar/list/main.css',
], (
  $, _, Backbone, Template,
  EmployeeCalendarItemsCollection, EmployeeCalendarCollection, EmployeeCollection,
  CurrentOrderItemCollection, ItemCollection, CalendarItemTypeCollection,
  SelectedCustomerModel,
  Loader,
  Moment, Locale, Toastr,
  CalendarEventPopup, ConfirmPopup,
  Fullcalendar, FullcalendarDaygrid, FullcalendarTimegrid, FullcalendarResourceDaygrid, FullcalendarResourceTimegrid,
  FullcalendarInteraction, FullcalendarList, FullcalendarMoment, FullcalendarBootstrap,
  FullcalendarLocales,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'calendar-overview__calendar',

  ui: {
    calendar: '[data-ui="calendar"]',
  },

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  modelEvents: {
    'change:relation_data_id': 'updateEvents',
  },

  initialize() {
    this.collection = EmployeeCalendarItemsCollection;
    this.itemCollection = new ItemCollection();
  },

  onShow() {
    this.showCalendar();
  },

  showCalendar() {
    // Small render delay
    setTimeout(() => {
      const el = this.ui.calendar.get(0);
      if (el) {
        // Documentation: https://fullcalendar.io/docs
        this.calendar = new Fullcalendar.Calendar(el, {
          scrollTime: new Moment().subtract(3, 'hours').format('HH:mm:ss'),	// Center the now indicator vertically
          slotDuration: '00:15:00',	// Show 15 minutes increments
          slotLabelFormat: {	// Always show 'hh:mm'
            hour: 'numeric',
            minute: '2-digit',
            omitZeroMinute: false,
            meridiem: 'short',
          },
          timeGridEventMinHeight: 30, // Make sure that appointments are not 1px high
          locales: FullcalendarLocales,
          locale: Locale.getLocale(),
          defaultView: 'resourceTimeGridDay',
          themeSystem: 'bootstrap',
          nowIndicator: true,
          weekNumbers: true,
          allDaySlot: false,
          contentHeight: this.el.clientHeight - 75,
          schedulerLicenseKey: 'GPL-My-Project-Is-Open-Source',
          resources: EmployeeCollection.map((model) => ({
            id: model.get('group_member.relation_data_id'),
            title: model.get('managed_subuser.relation_data_profile.name'),
          })),
          validRange: {
            start: new Moment().subtract('weeks', 1).format(),
          },
          header: {
            left: 'today',
            center: 'listWeek,resourceTimeGridDay,timeGridWeek,dayGridMonth,title',
            right: 'prev,next',
          },
          plugins: [
            FullcalendarDaygrid.default,
            FullcalendarTimegrid.default,
            FullcalendarResourceDaygrid.default,
            FullcalendarResourceTimegrid.default,
            FullcalendarInteraction.default,
            FullcalendarList.default,
            FullcalendarMoment.default,
            FullcalendarBootstrap.default,
          ],
          dateClick: (info) => {
            this.handleClick(info);
          },
          eventClick: (info) => {
            this.handleClick(info);
          },
          eventRender: (info) => {
            this.handleEventRender(info);
          },
          businessHours: false,
        });
        this.calendar.render();

        const def = this.loader.startLoader();
        this.collection.unload(); // Reset full collection.
        this.itemCollection.reset(); // Reset full collection.
        this.fetchCollection(true) // Always try to fetch new shit
          .then(def.resolve, def.reject);
      }
    }, 10);
  },

  handleEventRender(info) {
    const id = parseInt(info.event.id);
    let model = this.collection.get(id);
    if (!model) {
      model = this.itemCollection.get(id);
    }

    const content = info.el.querySelector('.fc-content');
    if (content) {
      // Description
      let description = model.get('description');
      if (!description) {
        description = model.get('item.description');
      }
      if (model && description) {
        const descriptionElement = document.createElement('small');
        descriptionElement.classList.add('fc-description');
        descriptionElement.innerText = description;

        content.append(descriptionElement);
      }

      // Add icon
      const icon = this.getItemIcon(model);
      const titleEl = content.querySelector('.fc-title');
      if (icon && titleEl) {
        // Add a space in front of the text
        const text = titleEl.innerText;
        titleEl.innerText = ` ${text}`;

        // Add icon
        const iconElement = document.createElement('i');
        $(iconElement).addClass(icon);
        titleEl.prepend(iconElement);
      }
    }
  },

  getItemIcon(model) {
    if (model && model.has('item_type_id')) {
      const item_type_id = model.get('item_type_id');
      const CalendarItemTypeModel = CalendarItemTypeCollection.get(item_type_id);
      const alias = CalendarItemTypeModel.get('alias');
      switch (alias) {
        case 'Break':
          return 'fa fa-pause';
        case 'Internal':
          return 'fa fa-building';
        case 'NotAvailable':
          return 'fa fa-exclamation-triangle';
      }
    }
  },

  fetchCollection(force) {
    // Load items
    const def = new $.Deferred();

    this.collection.load(force)
      .then(() => {
        this.itemCollection.fetchAll(this._getItemParameters())
          .then(def.resolve, def.reject);
      }, def.reject);

    // Once items are fetched
    def.then(() => {
      this.updateEvents();
    });

    return def;
  },

  _getItemParameters() {
    const parameters = {
      start: 0,
      limit: 250,
      filters: [
        {
          name: 'calendar_id__in_list',
          multi_val: EmployeeCalendarCollection.pluck('id'),
        }, {
          name: 'date_start__>',
          val: new Moment().startOf('week').subtract('weeks', 1).format(),
        }, {
          name: 'id__not_in_list',
          multi_val: EmployeeCalendarItemsCollection.pluck('item_id'),
        },
      ],
    };

    // Add last fetch parameter
    if (this.dateLastFetch) {
      parameters.filters.push({
        name: 'item/date_updated__>',
        val: this.dateLastFetch.format(),
      });
    }

    return {
      params: parameters,
      add: true,
      merge: true,
      remove: false,
    };
  },

  createInitials(calendarModel) {
    if (calendarModel) {
      const employeeName = calendarModel.get('relation_data.name');// e.g. "John doe Ramen"
      const initials = employeeName.match(/\b\w/g) || [];// e.g. "J, d ,R"
      return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();// e.g. JR
    }
  },

  updateEvents() {
    // clear all events
    this.calendar.getEvents()
      .forEach((event) => event.remove());

    // appointment add function
    const addModelToCalendar = (model) => {
      const calendarModel = EmployeeCalendarCollection.get(model.get('item.calendar_id'));
      const employeeInitials = this.createInitials(calendarModel);

      this.calendar
        .addEvent({
          id: model.get('item_id'),
          title: `${employeeInitials} | ${model.get('item.title')}`,
          description: model.get('item.description'),
          start: model.get('item.date_start'),
          end: model.get('item.date_end'),
          resourceId: calendarModel.get('relation_data_id'),
          backgroundColor: calendarModel.get('color'),
        });
    };

    // Item add function
    const addItemToCalendar = (model) => {
      const calendarModel = EmployeeCalendarCollection.get(model.get('calendar_id'));
      const employeeInitials = this.createInitials(calendarModel);

      // https://fullcalendar.io/docs/Calendar-addEvent
      this.calendar
        .addEvent({
          id: model.get('id'),
          title: `${employeeInitials} | ${model.get('title')}`,
          description: model.get('description'),
          start: model.get('date_start'),
          end: model.get('date_end'),
          resourceId: calendarModel.get('relation_data_id'),
          backgroundColor: 'gray', // gray for all backoffice events
        });
    };

    // Check if the events should be limited
    const relationDataId = this.model.get('relation_data_id');
    if (relationDataId) {
      const calendarModel = EmployeeCalendarCollection.findWhere({ relation_data_id: parseInt(relationDataId) });
      if (calendarModel) {
        const calendarId = calendarModel.get('id');
        this.collection.each((model) => {
          if (calendarId === model.get('item.calendar_id')) {
            addModelToCalendar(model);
          }
        });
        this.itemCollection.each((model) => {
          if (calendarId === model.get('calendar_id')) {
            addItemToCalendar(model);
          }
        });
      }
    }
    // Else add all
    else {
      this.collection.each(addModelToCalendar);
      this.itemCollection.each(addItemToCalendar);
    }
  },

  events: {
    'click [data-action="add-event"]': 'addEventClicked',
  },

  addEventClicked() {
    this.handleClick({
      date: this.calendar.getDate(),
    });
  },

  regions: {
    popup: '[data-region=popup]',
  },

  deleteAppointment(item_id) {
    const modelToRemove = this.collection.get(item_id);
    this.collection.remove(modelToRemove);
  },

  openEventPopup(data) {
    const view = new CalendarEventPopup(data);

    const region = this.getRegion('popup');
    region.show(view);

    view.open()
      .then((resp) => {
        // Check if the event needs to be destroyed
        if (resp && 'deletedModel' in resp) {
          const model = resp.deletedModel;
          const confirmView = new ConfirmPopup();
          region.show(confirmView);
          confirmView.open(Locale.translate('remove_appointment_for_{0}_question', model.get('customer.name')))
            .then(() => {
              // If so, remove it.
              const def = this.loader.startLoader();
              model.destroy()
                .then(() => {
                  this.deleteAppointment(model.get('item_id'));
                  this.fetchCollection(true) // Always try to fetch new shit
                    .then(def.resolve, def.reject);
                }, def.reject);
            });
        }

        // Customer wants to pay for an item
        else if (resp && 'payedModel' in resp) {
          const appointmentModel = resp.payedModel;
          // check if items in current order > ask if they want to clear them
          const checkDef = new $.Deferred();
          if (CurrentOrderItemCollection.length > 0) {
            const confirmView = new ConfirmPopup();
            region.show(confirmView);
            confirmView.open(Locale.translate('there_are_items_in_your_current_order_these_will_be_removed_when_proceeding_dot'))
              .then(() => {
                CurrentOrderItemCollection.clear();
                checkDef.resolve();
              }, checkDef.resolve);
          } else {
            checkDef.resolve();
          }

          // Async Select a customer
          // Once found
          SelectedCustomerModel.selectByRelationDataId(appointmentModel.get('customer_id'))
            .fail((resp) => {
              if (resp && 'error' in resp) {
                Toastr.error(resp.error);
              }
            });

          checkDef.then(() => {
            const orderItems = appointmentModel.get('order.order_items') || [];

            // Add all order items from the model
            orderItems.forEach((orderItem) => {
              const orderItemModel = new Backbone.DeepModel(orderItem);
              const model = CurrentOrderItemCollection.addProductByOrderItemModel({
                orderItemModel,
                forceNew: true,
              });
              model.set('description', Locale.translate('duration_{0}', this.formatDuration(orderItemModel.get('extra.duration_in_seconds'))));
              model.save();
            });

            Backbone.history.navigate('checkout', { trigger: true });
          });
        }

        // Customer wants to pay for an item
        else if (resp && 'savedModel' in resp) {
          const appointmentModel = resp.savedModel;
          const def = this.loader.startLoader();

          appointmentModel.fetch()
            .then((resp) => {
              // explicit removal is needed because else
              // collection.add is not overwriting the old model for some reason
              this.deleteAppointment(appointmentModel.get('item_id'));

              // Add new model
              this.collection.add(resp);
              this.updateEvents();
              def.resolve();
            }, def.reject);
        }
        // Else just update the calendar
        else {
          const def = this.loader.startLoader();
          this.fetchCollection(true) // Always try to fetch new shit
            .then(def.resolve, def.reject);
        }
      });
  },

  handleClick(info) {
    let data = {};

    if (info && 'event' in info) {
      const { id } = info.event;
      const model = this.collection.get(id);
      data = _.extend({}, data, {
        model,
      });
    } else {
      data = _.extend({}, data, {
        date_start: new Moment(info.date).format(),
      });
    }

    const relationDataId = this.model.get('relation_data_id');
    if (relationDataId) {
      const calendarModel = EmployeeCalendarCollection.findWhere({ relation_data_id: parseInt(relationDataId) });
      if (calendarModel) {
        data = _.extend({}, data, {
          calendar_id: calendarModel.get('id'),
        });
      }
    }

    this.openEventPopup(data);
  },

  formatDuration(duration_in_seconds) {
    const hours = (Math.floor(duration_in_seconds / 3600)).toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false });
    duration_in_seconds %= 3600;
    const minutes = (Math.floor(duration_in_seconds / 60)).toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false });
    const seconds = (duration_in_seconds % 60).toLocaleString('nl-NL', { minimumIntegerDigits: 2, useGrouping: false });

    return `${hours}:${minutes}:${seconds}`;
  },

}));
