define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',

  'modules/shop.cash-register-retail/models/settings/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/shopPos',

  'modules/shop.cash-register-retail/models/shopPos',
], (
  $, _, Backbone, SwappableView,
  LayoutView,
  PointOfSaleSetting, ShopPosSetting,
  ShopPosModel,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(LayoutView);

    this.shopId = options.shopId;
  },

  loadSharedData(dataStorage) {
    const def = new $.Deferred();

    PointOfSaleSetting.fetch()
      .then(() => {
        let shopPos = dataStorage.shopPos = ShopPosSetting.clone();
        if (this.shopId) {
          shopPos = dataStorage.shopPos = new ShopPosModel({ shop_id: this.shopId });
        }

        $.when(
          shopPos.fetchGalleryItems(),
          shopPos.loadIfPossible(true),
        ).then((galleryItemCollection) => {
          dataStorage.galleryItemCollection = galleryItemCollection;
          def.resolve();
        }, def.reject);
      }, def.reject);

    return def;
  },

}));
