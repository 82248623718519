define([
  'jquery',
  'underscore',
  'backbone',
], (
  $, _, Backbone,
) => Backbone.Marionette.LayoutView.extend({
  template: () => '&nbsp;',

  tagName: 'td',

  // Render the view if the view is not destroyed yet
  maybeRender() {
    if (!this.isDestroyed) {
      this.render();
    }
  },

  initialize(options) {
    this.columnDefinitionModel = options.columnDefinitionModel;
    this.listenTo(this.columnDefinitionModel, 'change:visible', this.toggleVisible);
  },

  toggleVisible(model) {
    model = model || this.columnDefinitionModel;
    if (model.get('visible')) {
      this.$el.show();
    } else {
      this.$el.hide();
    }
  },

  onRender() {
    this.toggleVisible();
  },
}));
