define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/loader',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className: 'loader-container',

  initialize(options) {
    this.tableModel = options.tableModel;
  },

  onShow() {
    this.tableModel.on('change:loading', this.render, this);
  },

  onDestroy() {
    this.tableModel.off('change:loading', this.render, this);
  },

  serializeData() {
    return {
      showLoader: !!this.tableModel.get('loading'),
    };
  },

}));
