define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/limit.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  tagName: 'select',

  className: 'form-control limit',

  events: {
    change: 'quantityChanged',
  },

  initialize(options) {
    this.tableModel = options.tableModel;
  },

  onShow() {
    this.setQuantity();

    this.tableModel.on('change:limit', this.setQuantity, this);
  },

  onDestroy() {
    this.tableModel.off('change:limit', this.setQuantity, this);
  },

  setQuantity() {
    this.$el.val(this.tableModel.get('limit'));
  },

  getQuantity() {
    return parseInt(this.$el.val());
  },

  quantityChanged() {
    this.tableModel.set('limit', this.getQuantity());
  },

  serializeData() {
    return {
      collection: this.collection.toJSON(),
    };
  },

}));
