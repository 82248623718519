define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './view',
  './edit',
  './outstandingInvoices/collection',
  './loyalty',

  'upx.modules/BillingModule/models/InvoiceStat',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
  'upx.modules/ShopModule/collections/LoyaltyCustomerOperation',
  'modules/upx/components/upx',

  'modules/shop.cash-register-retail/collections/upx/LoyaltyOperationTypes',
  'upx.modules/BillingModule/collections/Invoice',
  'modules/shop.cash-register-retail/collections/routeCache',
  'modules/common/components/historyBreadcrumb',

  'modules/shop.cash-register-retail/components/customer',
], (
  $, _, Backbone, SwappableView,
  CustomerViewView, CustomerEditView, OutstandingView, LoyaltyView,
  InvoiceStatModel, LoyaltyProgramModel, LoyaltyCustomerOperationCollection, Upx,
  LoyaltyOperationTypes, InvoiceCollection, RouteCache, HistoryBreadcrumb,
  CustomerComponent,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    this.setMainView(CustomerViewView);
    this.setView('edit', CustomerEditView);
    this.setView('loyalty', LoyaltyView);
    this.setView('outstanding', OutstandingView);
  },

  ensureExtraRelationData() {
    const def = new $.Deferred();
    $.when(
      this.ensureOutstandingInvoiceStats(this.model),
      this.ensureLoyaltyStats(this.model),
      this.ensurePermanentDiscount(this.model),
    ).then(def.resolve, def.reject);
    return def.promise();
  },

  ensurePermanentDiscount(model) {
    const def = new $.Deferred();

    CustomerComponent.getPermanentDiscount({
      relation_data_id: model.get('id'),
    }).then((permanentDiscount) => {
      model.set('permanent_discount', permanentDiscount);
      def.resolve();
    }, def.reject);

    return def;
  },

  ensureOutstandingInvoiceStats(model) {
    const def = new $.Deferred();

    if (model.has('outstanding_invoice_stats')) {
      def.resolve();
    } else {
      const invoiceStatModel = new InvoiceStatModel();
      const parameters = {
        start: 0,
        limit: 1,
        filters: [{
          name: 'relation_data_to_id__=',
          val: model.get('id'),
        }],
      };

      invoiceStatModel.listByRelationTo(parameters)
        .then((resp) => {
          const { data } = resp;
          model.set('outstanding_invoice_stats', _.first(data));
          def.resolve();
        }, def.reject);
    }

    return def;
  },

  ensureLoyaltyStats(model) {
    const def = new $.Deferred();
    model.set('loyalty_program_active', LoyaltyProgramModel.isActive());
    model.set('loyalty_program_id', LoyaltyProgramModel.get('id'));
    if (!LoyaltyProgramModel.isActive() || model.has('loyalty_customer')) {
      def.resolve();
    } else {
      LoyaltyProgramModel.fetchLoyaltyCustomer(model.get('id')).then(
        (data) => {
          model.set('loyalty_customer', data);
          def.resolve();
        },
        def.reject,
      );
    }

    return def;
  },

  loadSharedData(dataStorage) {
    dataStorage.model = this.model;
    return LoyaltyProgramModel.load();
  },

  loadDataForView(name, childOptions, dataStorage) {
    const def = new $.Deferred();

    if (!name) {
      this.ensureExtraRelationData()
        .then(def.resolve, def.reject);
    } else if (name === 'loyalty') {
      const { model } = dataStorage;
      $.when(
        LoyaltyOperationTypes.load(),
        this.ensureLoyaltyStats(model),
      ).then(() => {
        const loyalty_customer_id = model.get('loyalty_customer.id');
        dataStorage.loyaltyCustomerOperationCollection = new LoyaltyCustomerOperationCollection();
        if (loyalty_customer_id) {
          dataStorage.loyaltyCustomerOperationCollection.fetch({
            params: {
              start: 0,
              limit: 5,
              sort: [{
                name: 'date_created',
                dir: 'desc',
              }],
              filters: [
                {
                  name: 'loyalty_customer_id__=',
                  val: loyalty_customer_id,
                },
              ],
            },
          }).then(() => {
            dataStorage.loyaltyCustomerOperationCollection.each((oper) => {
              oper.set(
                'loyalty_operation_type',
                LoyaltyOperationTypes.get(oper.get('loyalty_operation_type_id')).toJSON(),
              );
            });
            def.resolve();
          }, def.reject);
        } else {
          def.resolve();
        }
      }, def.reject);
    } else if (name === 'outstanding') {
      const { model } = dataStorage;
      const collection = new InvoiceCollection();
      const params = {
        start: 0,
        limit: 0,
        filters: [
          {
            name: 'is_paid__=',
            val: false,
          },
          {
            name: 'relation_data_to_id__=',
            val: model.get('id'),
          },
          {
            name: 'value_outstanding_wt__!=',
            val: false,
          },
        ],
      };
      $.when(
        collection.fetch({ params }),
        this.ensureOutstandingInvoiceStats(model),
      ).then(({ total }) => {
        if (total === 1) {
          def.resolve();

          const firstModel = collection.first();
          const cacheId = RouteCache.createCache({
            invoiceId: firstModel.get('id'),
          });
          HistoryBreadcrumb.getBackRoute(); // pop current route, we should not go back here
          HistoryBreadcrumb.goto(`payment-screen/invoice/${cacheId}`);
        } else {
          dataStorage.collection = collection;
          def.resolve();
        }
      }, def.reject);
    } else {
      def.resolve();
    }

    return def;
  },

}));
