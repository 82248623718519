define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/checkoutButtons/item',
  'modules/shop.cash-register-retail/components/checkoutCards',

  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/shop.common/components/deviceConfig',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/collections/upx/LoyaltyOperationTypes',

  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',

  'modules/common/components/locale',

  'modules/shop.cash-register-retail/views/popups/categoryPopup',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.cash-register-retail/views/popups/addLoyaltyOperationPopup',

  'modules/admin/behaviors/loader',

], (
  $, _, Backbone, Template, CheckoutCardsComponent,
  OrderItemCollection, DeviceConfig, SelectedCustomer, LoyaltyOperationTypes,
  LoyaltyProgramModel,
  Locale,
  CategoryPopupView, MessagePopupView, AddLoyaltyOperationPopup,
  Loader,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,
  className: 'full-child-height',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click button': 'itemClicked',
    'touchend button': 'itemClicked',
  },

  regions: {
    popup: '[data-region="popup"]',
  },

  scrollToTop: _.debounce(() => {
    // Scroll to last item
    const productList = $('#products-list').get(0);
    if (productList) {
      productList.scrollTop = 0;
    }
  }, 50),

  getButtonShopProductModel() {
    const data = this.model.get('flat_shop_product');
    return new Backbone.DeepModel($.extend(true, {}, data));// make sure to clone the object
  },

  addProductToOrderList(productModel, type, forceNew) {
    const def = new $.Deferred();
    let quantity = 1;
    if (type === OrderItemCollection.TYPE_NEGATIVE_PRODUCT) {
      quantity = -1;
    }
    OrderItemCollection.addProductByShopFlatProductModel(
      productModel,
      quantity,
      forceNew,
      type,
    ).then(
      (orderItemModel) => {
        this.scrollToTop();
        def.resolve(orderItemModel);
        if (quantity > 0) {
          OrderItemCollection.refreshProductStockIfProductStockIsUsed(orderItemModel);
        }
      },
      def.reject,
    );
    return def.promise();
  },

  addDashboardButtonBlankShopProduct(def) {
    def.resolve();
    this.addProductToOrderList(
      this.getButtonShopProductModel(),
      null,
      true,
    ).then(
      (model) => {
        model.set({
          before_discount_ppu_wt: '0.00',
          before_discount_ppu: '0.00',
          ppu_wt: '0.00',
          ppu: '0.00',
          price_wt: '0.00',
          price: '0.00',
        });
        model.save();

        // wait for scroll
        setTimeout(() => {
          const $el = $(`[data-ppu-wt="${model.get('id')}"]`);
          $el.click();
        }, 5);
      },
    );
  },

  addDashboardButtonShopProduct(def) {
    def.resolve();
    this.addProductToOrderList(
      this.getButtonShopProductModel(),
    );
  },

  addDashboardButtonNegativeShopProduct(def) {
    def.resolve();
    this.addProductToOrderList(
      this.getButtonShopProductModel(),
      OrderItemCollection.TYPE_NEGATIVE_PRODUCT,
    );
  },

  addDashboardButtonGiftCard(def) {
    CheckoutCardsComponent.giftCardPopup(def).then(
      () => this.scrollToTop(),
    );
  },

  addDeductLoyaltyPoints(def) {
    const relationDataId = SelectedCustomer.get('id');
    const showError = (message) => {
      def.reject();
      const view = new MessagePopupView();
      view.open(message);
    };
    if (relationDataId) {
      const customerModel = new Backbone.DeepModel(
        $.extend(true, {}, SelectedCustomer.toJSON()),
      );
      $.when(
        LoyaltyOperationTypes.load(),
        LoyaltyProgramModel.load(),
      ).then(
        () => {
          if (!LoyaltyProgramModel.isActive()) {
            showError(Locale.translate('loyalty_program_is_not_active'));
          } else {
            LoyaltyProgramModel.fetchLoyaltyCustomer(relationDataId).then(
              (data) => {
                if (!data) {
                  showError(Locale.translate('this_customer_is_not_in_the_loyalty_program_heshe_will_autojoin_on_first_paid_order'));
                } else {
                  customerModel.set('loyalty_customer', data);
                  const view = new AddLoyaltyOperationPopup({
                    safeDef: new $.Deferred(),
                    addPoints: false,
                    customerModel,
                    inCheckout: true,
                  });
                  view.open(def);
                }
              },
              def.reject,
            );
          }
        },
        (result) => {
          showError(Locale.translate(result.error));
        },
      );
    } else {
      showError(Locale.translate('select_a_customer_first_dot'));
    }
  },

  addDashboardButtonCategory(def) {
    const category_id = this.model.get('category_id');
    if (category_id) {
      const view = new CategoryPopupView();
      view.on('child:clicked', (model) => {
        def.resolve();
        this.addProductToOrderList(model);
      });
      view.open(
        false,
        category_id,
        this.model.get('category_title'),
        true,
      ).always(
        def.reject,
      );
    } else {
      def.reject({
        error: Locale.translate('no_category_set_for_this_button'),
      });
    }
  },

  addDashboardButtonMemberCard(def) {
    CheckoutCardsComponent.memberCardPopup(def).then(
      () => this.scrollToTop(),
    );
  },

  addDashboardButtonTopUp(def) {
    CheckoutCardsComponent.topUpPopup(def);
  },
  addDashboardButtonBoughtUsedGoods(def) {
    CheckoutCardsComponent.usedGoodsPopup(def, this.model.get('tax_rate_id'));
  },

  itemClicked: _.debounce(function (e) {
    const $el = $(e.currentTarget);
    $el.blur();

    const def = this.loader.startLoader();
    OrderItemCollection.addLoaderByDef(def);
    const { model } = this;

    const typeAlias = model.get('type_alias');
    switch (typeAlias) {
      case DeviceConfig.ALIAS_ButtonBlankShopProduct:
        this.addDashboardButtonBlankShopProduct(def);
        break;
      case DeviceConfig.ALIAS_ButtonShopProduct:
        this.addDashboardButtonShopProduct(def);
        break;
      case DeviceConfig.ALIAS_DashboardButtonNegativeShopProductType:
        this.addDashboardButtonNegativeShopProduct(def);
        break;
      case DeviceConfig.ALIAS_ButtonDeductLoyaltyPoints:
        this.addDeductLoyaltyPoints(def);
        break;
      case DeviceConfig.ALIAS_ButtonGiftCard:
        this.addDashboardButtonGiftCard(def);
        break;
      case DeviceConfig.ALIAS_ButtonMemberCard:
        this.addDashboardButtonMemberCard(def);
        break;
      case DeviceConfig.ALIAS_ButtonCategory:
        this.addDashboardButtonCategory(def);
        break;
      case DeviceConfig.ALIAS_ButtonTopUp:
        this.addDashboardButtonTopUp(def);
        break;
      case DeviceConfig.ALIAS_ButtonBoughtUsedGoods:
        this.addDashboardButtonBoughtUsedGoods(def);
        break;
      default:
        def.reject({
          error: `Unknown button type alias ${typeAlias}`,
        });
    }

    def.fail((response) => {
      if (response && 'error' in response) {
        const view = new MessagePopupView();
        view.open(
          response.error,
        );
      }
    });
  }, 200),
}));
