define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/views/datatables/table/cell',
  'modules/shop.cash-register-retail/templates/datatables/table/bodyCell',
], (
  $, _, Backbone, Cell, Template,
) => Cell.extend({
  template: Template,

  className() {
    let className = '';

    if (this.options.columnDefinitionModel.has('bodyCellClassName')) {
      className += [className, this.options.columnDefinitionModel.get('bodyCellClassName')].join(' ');
    }

    return className;
  },

  modelEvents() {
    const events = {};
    if (this.columnDefinitionModel.has('path')) {
      events[`change:${this.columnDefinitionModel.get('path')}`] = 'onPathValueChangeDebounced';
    }
    return events;
  },

  onPathValueChange() {
    this.maybeRender();
  },

  initialize(options) {
    Cell.prototype.initialize.call(this, options);
    this.tableView = options.tableView;
    this.onPathValueChangeDebounced = _.debounce(
      (e) => this.onPathValueChange(e),
      5,
    );
  },

  getDataForPath() {
    let data = null;
    let path = null;
    let translationPath = null;
    if (this.columnDefinitionModel.has('path')) {
      path = this.columnDefinitionModel.get('path');
    }
    if (this.columnDefinitionModel.has('translationPath')) {
      translationPath = this.columnDefinitionModel.get('translationPath');
    }

    /**
             * First checks if translationPath is a string and uses that as model path
             * Secondly checks if translationPath is true, and uses translation.<path> as path for the model
             * Thirdly checks if the path is set in the model
             * Lastly sets data to the detault value;
             */
    if (!_.isNull(translationPath) && _.isString(translationPath) && this.model.has(translationPath)) {
      data = this.model.get(path);
    } else if (!_.isNull(translationPath) && translationPath === true && this.model.has(`translation.${path}`) && this.model.get(`translation.${path}`) !== '') {
      data = this.model.get(`translation.${path}`);
    } else if (!_.isNull(path) && this.model.has(path)) {
      data = this.model.get(path);
    } else {
      data = this.columnDefinitionModel.get('default');
    }
    return data;
  },

  getContent() {
    const data = this.getDataForPath();
    return this.columnDefinitionModel.getBodyCellContent(data, this.model);
  },

  getBodyCellRenderIsSafe() {
    return this.columnDefinitionModel.get('bodyCellRenderIsSafe');
  },

  serializeData() {
    return {
      content: this.getContent(),
      bodyCellRenderIsSafe: this.getBodyCellRenderIsSafe(),
    };
  },
}));
