define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/create.hbs',

  'modules/shop.cash-register-retail/views/inputs/companyKvkSearchInput',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.common/components/dateTime',
  'modules/common/components/moment',

  'modules/upx/behaviors/memento',

  'modules/admin/behaviors/loader',
  'modules/upx/behaviors/validation',
  'modules/upx/behaviors/modelBinder',

  'modules/common/collections/countries',
  'modules/common/collections/languages',
  'upx.modules/RelationsModule/collections/FullInfoRelation',

  'modules/profile/models/profile',
  'upx.modules/ShopModule/models/ShopRelation',
  'upx.modules/RelationsModule/models/Address',
  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',

  'modules/shop.cash-register-retail/views/popups/messagePopup',
  'modules/shop.common/components/crypto',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone, Template,
  CompanyKvkSearchInputView,
  Locale, Toaster, CashRegisterApi, DateTime, Moment,
  Memento,
  Loader, Validation, ModelBinder,
  CountryCollection, LanguageCollection, FullInfoRelationCollection,
  profileModel, ShopRelationModel, AddressModel, SelectedCustomerModel, DefaultShopConfigurationModel,
  MessagePopupView, Crypto, HistoryBreadcrumb,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'customer-create',

  ui: {
    firstname: '[data-ui="firstname"]',
    salutation: '[data-ui="salutation"]',
    dataCheckbox: '[data-checkbox]',
    toBusinessBtn: '[data-action="to-business"]',
    toPrivateBtn: '[data-action="to-private"]',
    companySearch: '[data-region="company-search"]',
  },

  behaviors() {
    return {
      Loader: {
        behaviorClass: Loader,
      },
      ModelBinder: {
        behaviorClass: ModelBinder,
        method: 'newShopCustomer',
      },
      Memento: {
        behaviorClass: Memento,
      },
      Validation: {
        behaviorClass: Validation,
        method: 'newShopCustomer',
        nameSelectorFn: 'selectField',
        rules: {
          // company
          'relation.business_data.name': {
            fn: 'view.validateBasedOnPrivate',
          },
          // Name
          'relation.contact_person.firstname': {
            fn: 'view.validateBasedOnPrivate',
          },
          'relation.contact_person.familyname_prefix': {
            required: false,
          },
          'relation.contact_person.familyname': {
            fn: 'view.validateBasedOnPrivate',
          },
          // Contact info
          'relation.contact_set.phone': {
            required: false,
          },
          // Address
          'relation.contact_address.zipcode': {
            required: true,
          },
          'relation.contact_address.streetnumber': {
            required: true,
          },
          'relation.contact_address.flatnumber': {
            required: false,
          },
          'relation.contact_address.street': {
            required: true,
          },
          'relation.contact_address.country_iso2': {
            required: true,
          },
          // County and language
          'relation.language_iso2': {
            required: true,
          },
        },
      },
    };
  },

  events: {
    'click [data-action="save"]': 'saveClicked',
    'click [data-action="save-and-select"]': 'saveAndSelectClicked',
    'click [data-action="back"]': 'backClicked',
    'click @ui.toBusinessBtn': 'toBusinessBtnClicked',
    'click @ui.toPrivateBtn': 'toPrivateBtnClicked',

    'change [data-action="birthdate_month"]': 'monthChanged',

    'change @ui.dataCheckbox': 'dataCheckboxChanged',

    // addressCheck
    'change [name="relation.contact_address.zipcode"]': 'addressCheck',
    'change [name="relation.contact_address.streetnumber"]': 'addressCheck',
    'change [name="relation.contact_address.country_iso2"]': 'countryIso2Changes',
    'keyup [name="relation.contact_set.email"]': 'emailFix',
  },

  regions: {
    companySearch: '[data-region=company-search]',
    popup: '[data-region="popup"]',
  },

  addressModel: new AddressModel(),
  zipCodeRegex: /([\d]{4})([\w]{2})/,

  isPrivate() {
    return this.model.get('relation.isprivate');
  },

  selectField(name, nameSelector) {
    if (name === 'relation.business_data.name') {
      return this.ui.companySearch;
    }
    return this.$el.find(`[${nameSelector}="${name}"]`);
  },

  validateBasedOnPrivate(value, fieldName) {
    if (!value) {
      const isPrivate = this.isPrivate();
      if (!isPrivate && fieldName === 'relation.business_data.name') {
        return Locale.translate('fields_is_required');
      }
      if (isPrivate && (
        fieldName === 'relation.contact_person.firstname'
          || fieldName === 'relation.contact_person.familyname'
      )) {
        return Locale.translate('fields_is_required');
      }
    }
    return null;
  },

  backClicked(e) {
    e.stopPropagation(); // increases the touch speed
    e.preventDefault(); // increases the touch speed
    HistoryBreadcrumb.goBack();
  },

  toBusinessBtnClicked() {
    this.model.set('relation.isprivate', false);
    this.render();
  },
  toPrivateBtnClicked() {
    this.model.set('relation.isprivate', true);
    this.render();
  },
  /**
         * Delay needed to let the data in the model uopdate.
         */
  monthChanged: _.debounce(function () {
    const days = DateTime.getDayInMonthArray(this.model.get('birthdate.month'));
    if (days.length < this.model.get('birthdate.day')) {
      this.model.set('birthdate.day', days.length); // set days to max.
    }
    this.render();
  }, 100),

  initialize(options) {
    this.from_hospitality_mode = options.from_hospitality_mode ? options.from_hospitality_mode : false;
    this.model = new ShopRelationModel({
      'relation.isprivate': !!options.is_private,
      'relation.contact_address.country_iso2': this.getDefaultCountry(),
      'relation.language_iso2': this.getDefaultLanguage(),
      'relation.subuser': {
        login: 'user',
        groups: ['admins'], // Also done in the backend
      },
      birthdate: {
        day: 0,
        month: 0,
        year: 0,
      },
    });

    this.model.labels = {
      'relation.contact_address.street': Locale.translate('street_name'),
    };
  },

  onRender() {
    this.modelToViewCheckbox();

    if (!this.isPrivate()) {
      this.renderCompanySearch();
      this.ui.toBusinessBtn.hide();
      this.ui.toPrivateBtn.show();
    } else {
      this.ui.toBusinessBtn.show();
      this.ui.toPrivateBtn.hide();
    }
    switch (this.model.get('relation.contact_person.ismale')) {
      case '1':
      case true:
        this.ui.salutation.val('male');
        break;
      case '0':
      case false:
        this.ui.salutation.val('female');
        break;
      default:
        this.ui.salutation.val('unknown');
        break;
    }
  },

  modelToViewCheckbox() {
    this.ui.dataCheckbox.get().forEach((el) => {
      const name = el.dataset.checkbox;
      el.checked = this.model.get(name) || false;
    });
  },

  countryIso2Changes() {
    this.addressCheck();
    this.delayRenderCompanySearch();
  },

  // Debounce needed to ensure the data is loaded into the model.
  delayRenderCompanySearch: _.debounce(function () {
    this.renderCompanySearch();
  }, 0),

  renderCompanySearch() {
    const region = this.getRegion('companySearch');
    const view = new CompanyKvkSearchInputView({
      placeholderSearch: Locale.translate('type_to_search'),
      placeholderInput: Locale.translate('company_name'),
      countryIso2: this.model.get('relation.contact_address.country_iso2'),
      companyName: this.model.get('relation.business_data.name') || '',
    });
    region.show(view);

    view.on('change:iso2', (iso2) => {
      this.model.set('relation.contact_address.country_iso2', iso2);
    });

    view.on('change:name', (name) => {
      this.model.set('relation.business_data.name', name);
    });

    view.on('company:selected', (data) => {
      this.model.set({
        'relation.business_data': data.business_data || {},
        'relation.contact_address': data.contact_address || {},
      });
    });
  },

  emailFix: _.debounce(function (e) {
    this.model.set('relation.subuser.email', $(e.currentTarget).val());
  }, 10),

  addressCheck: _.debounce(function () {
    const address = this.model.get('relation.contact_address');
    if (
      address && address.streetnumber && address.zipcode && address.country_iso2
    ) {
      const { zipcode } = address;
      const { streetnumber } = address;
      const country_iso2 = address.country_iso2 || 'NL';

      const self = this;
      this.addressModel.searchByZipcode({
        zipcode,
        streetnumber,
        country_iso2,
      }).then((response) => {
        self.model.set({
          'relation.contact_address.street': response.street,
          'relation.contact_address.city': response.city,
          'relation.contact_address.state': response.state,
        });
        const fields = [
          'relation.contact_address.street',
          'relation.contact_address.city',
        ];
        fields.forEach((field) => {
          const $el = self.$el.find(`[name="${field}"]`);
          $el.trigger('change');
        });
      });
    }
  }, 10),

  getMainCountries() {
    const wantedCountries = ['nl', 'de', 'be', 'lu', 'gb'];
    return CountryCollection.chain()
      .filter((model) => {
        const iso2 = model.get('iso2');
        return _.contains(wantedCountries, iso2.toLowerCase());
      })
      .map((model) => model.toJSON())
      .value();
  },

  dataCheckboxChanged(ev) {
    const el = ev.currentTarget;
    const name = el.dataset.checkbox;
    const value = el.checked;
    this.model.set(name, value);
  },

  getMainLanguages() {
    const wantedLangauges = ['nl', 'de', 'en'];
    return LanguageCollection.chain()
      .filter((model) => {
        const iso2 = model.get('iso2');
        return _.contains(wantedLangauges, iso2.toLowerCase());
      })
      .map((model) => model.toJSON())
      .value();
  },

  prepareModel() {
    this.model.set('relation.business_data.country_iso2', this.model.get('relation.contact_address.country_iso2'));

    const day = this.model.get('birthdate.day');
    const month = this.model.get('birthdate.month');
    const year = this.model.get('birthdate.year');
    if (
      parseInt(day) > 0
                && parseInt(month) > 0
                && parseInt(year) > 0
    ) {
      const birthdate = new Moment();
      birthdate.date(day);
      birthdate.month(month - 1);
      birthdate.year(year);
      this.model.set('relation.contact_person.birthdate', birthdate.format());
    }

    switch (this.ui.salutation.val()) {
      case 'male':
        this.model.set('relation.contact_person.ismale', '1');
        break;
      case 'female':
        this.model.set('relation.contact_person.ismale', '0');
        break;
      case 'unknown':
        this.model.unset('relation.contact_person.ismale');
        this.model.set('relation.contact_person.ismale__null', true);
        break;
    }
  },

  onSaved(model) {
    SelectedCustomerModel.clear();
    SelectedCustomerModel.set(model.toJSON());
    SelectedCustomerModel.save();
    Backbone.history.navigate('checkout', { trigger: true });
  },

  saveCustomer(select_customer) {
    this.prepareModel();
    if (this.model.isValid(true)) {
      if (!this.model.get('relation.contact_set.email')) {
        // no email is set, let's generate one as it's required for the backend
        this.model.set('relation.contact_set.email', `nomail+${Crypto.uuid()}@storekeeper.nl`);
      }
      const self = this;
      const def = this.loader.startLoader();
      const data = this.model.toJSON();
      delete data.birthdate;
      CashRegisterApi.logAction('CUSTOMER_CREATION_START', {
        model: data,
      });
      this.model.newShopCustomer({ fields: data })
        .then((relation_data_id) => {
          if (select_customer) {
            // select the newly created customer
            const fullInfoRelationCollection = new FullInfoRelationCollection();
            const params = {
              params: {
                start: 0,
                limit: 1,
                filters: [{
                  name: 'id__=',
                  val: relation_data_id,
                }],
              },
            };
            fullInfoRelationCollection.fetch(params)
              .then(() => {
                const model = fullInfoRelationCollection.first();
                if (model) {
                  self.onSaved(model);
                  Toaster.info(Locale.translate('customer_created_successfully_and_selected'));
                  def.resolve();
                } else {
                  def.reject({ error: Locale.translate('customer_not_found_after_creation') });
                }
              }, () => {
                def.reject({ error: Locale.translate('customer_not_found_after_creation') });
              });
          } else {
            // nothing to do, resolve
            Toaster.info(Locale.translate('customer_created_successfully'));
            Backbone.history.navigate('customers/search', { trigger: true });
            def.resolve();
          }
        },
        // fails
        (response) => {
          let message = Locale.translate('something_went_wrong_during_customer_creation_dot');
          if ('error' in response) {
            message = response.error;
          }

          message = self.getHumanMessage(message);

          const view = new MessagePopupView();
          view.open(message);
          def.reject();
        });

      def.then((id) => {
        CashRegisterApi.logAction('CUSTOMER_CREATION_SUCCESS', {
          model: data,
          id,
        });
      }, (error) => {
        CashRegisterApi.logAction('CUSTOMER_CREATION_ERROR', {
          model: data,
          error,
        });
      });
    }
  },

  getHumanMessage(message) {
    switch (message) {
      case 'Subuser with this email already exists':
        message = Locale.translate('there_is_already_a_customer_with_this_email_dot');
    }

    return message;
  },

  saveAndSelectClicked() {
    // select and redirect to checkout
    this.saveCustomer(true);
  },

  saveClicked() {
    // do not select and redirect to customer overview
    this.saveCustomer(false);
  },

  getDefaultCountry() {
    if (profileModel.has('relation_data.contact_address.country_iso2')) {
      return profileModel.get('relation_data.contact_address.country_iso2');
    }
    DefaultShopConfigurationModel.get('contact_address.country_iso2');
  },

  getDefaultLanguage() {
    if (profileModel.has('relation_data_profile.language_iso2')) {
      return profileModel.get('relation_data_profile.language_iso2');
    }
    Locale.getLocale();
  },

  onShow() {
    const self = this;
    // Set the focus to the search(if not in hospitality mode popup, because when it is in the hospitality mode popup there is too much moving on the screen) view and popup the keyboard by `clicking` on it
    if (!this.from_hospitality_mode && this.isPrivate()) {
      this.ui.firstname.focus();

      // Needed because it sometimes doesn't trigger the click event
      setTimeout(() => {
        self.ui.firstname.click();
      }, 50);
    }
  },

  serializeData() {
    const days = DateTime.getDayInMonthArray(this.model.get('birthdate.month'));
    const months = DateTime.getMonthArray();
    const years = DateTime.getYearArray(1900);

    days.unshift({
      number: 0,
      name: '-',
    });
    months.unshift({
      number: 0,
      name: '-',
    });
    years.unshift({
      number: 0,
      name: '-',
    });

    return {
      mainCountries: this.getMainCountries(),
      countries: CountryCollection.toJSON(),
      storesCountry: this.getDefaultCountry(),
      mainLanguages: this.getMainLanguages(),
      languages: LanguageCollection.toJSON(),
      usersLanguage: this.getDefaultLanguage(),
      is_private: this.isPrivate(),
      birthdate_days: days,
      birthdate_months: months,
      birthdate_years: years,
    };
  },

}));
