define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/list/item',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone, Template, HistoryBreadcrumb,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  tagName: 'tr',

  modelEvents: {
    'change:outstanding_invoice_stats': 'render',
  },

  events: {
    'click [data-customer]': 'customerClicked',
    'click [data-invoice]': 'invoiceClicked',
  },

  customerClicked() {
    if (this.options.resultDef) {
      this.options.resultDef.resolve(this.model);
    } else {
      HistoryBreadcrumb.goto(`customers/details/${this.model.get('id')}`);
    }
  },

  invoiceClicked() {
    if (this.options.resultDef) {
      this.options.resultDef.resolve(this.model);
    } else {
      HistoryBreadcrumb.goto(`customers/details/${this.model.get('id')}/outstanding`);
    }
  },

}));
