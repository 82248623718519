define([
  'jquery',
  'underscore',
  'modules/shop.cash-register-retail/views/datatables/baseLayout',

  'toastr',

], (
  $, _, BaseLayout,
  Toastr,
) => BaseLayout.extend({

  /**
         * Used for setting default filters
         * @override
         * @returns {Array}
         */
  getFilters() { return []; },

  /**
         * return the search name, mainly ushasSettingsFiltered when you search using the filters.
         * @override The name that needs to be user when searching
         */
  getSearchName() {},

  /**
         * Is the searchQuery should have % beside it.
         * @override
         * @return {boolean}
         */
  isFuzzySearch() {
    return false;
  },

  /**
         * Adding a search to the filter parameters. You can override this function to get.
         * ONLY use for setting of search parameters. Use the getFilters function to set initial filters.
         * @param {{params: {start: (number), limit: (Number), sort: (Array), filters: (Array)}}} parameters
         * @param {string} searchQuery
         * @returns {*}
         * @override when it doesnt use filters for searching
         */
  setSearchSettings(parameters, searchQuery) {
    searchQuery = searchQuery || '';
    if (searchQuery) {
      let searchValue = searchQuery;
      if (this.isFuzzySearch()) {
        searchValue = `%${searchQuery}%`;
      }
      parameters.params.filters.push({
        name: this.getSearchName(),
        val: searchValue,
      });
    }
    return parameters;
  },

  /**
         * Getting the starting possition for fetching
         * @returns {number}
         */
  getStart() {
    const page = parseInt(this.tableModel.get('page'));
    const quanity = parseInt(this.tableModel.get('limit'));
    if (page <= 0) {
      return 0;
    }
    return quanity * page;
  },

  /**
         * Getting the limit for fetching
         * @returns {Number}
         */
  getLimit() {
    return parseInt(this.tableModel.get('limit'));
  },

  /**
         * Getting the sorting array;
         * @returns {Array}
         */
  getSort() {
    const sort = [];

    if (!_.isNull(this.tableModel.get('sortName')) && !_.isNull(this.tableModel.get('sortDir'))) {
      sort.push({
        name: this.tableModel.get('sortName'),
        dir: this.tableModel.get('sortDir'),
      });
    }

    return sort;
  },

  getInitialFetchParameters() {
    return {
      start: this.getStart(),
      limit: this.getLimit(),
      sort: this.getSort(),
      filters: this.getFilters(),
    };
  },

  /**
         * Getting the full parameters for fetching.
         * Adding optional modifiers to the parameters
         * @returns {{params: {start: (number), limit: (Number), sort: (Array), filters: (Array)}}}
         */
  getFetchParameters() {
    const parameters = this.getInitialFetchParameters();

    this.settingCollection.each((settingModel) => {
      if (settingModel.isValidValue()) {
        settingModel.storeValue();
      } else {
        settingModel.restoreValue();
      }

      if (settingModel.isValidFilter()) {
        settingModel.applyParameters(parameters);
      }
    });

    return { params: parameters };
  },

  /**
         * updates the collection with the collected parameters
         * @private
         */
  _updateCollection() {
    const def = new $.Deferred();

    // Getting the initialFetchParameters
    let parameters = this.getFetchParameters();

    // Adding the search filter to the parameters
    parameters = this.setSearchSettings(parameters, this.tableModel.get('search'));

    // Adding the reset flag to the parameters
    parameters = _.extend({}, parameters, {
      reset: true,
    });

    const self = this;
    $.when(
      // Fetching the collection
      this.collection.fetch(parameters),
    ).then(
      (fetchedCollectionData) => {
        self.tableModel.set('collectionTotal', fetchedCollectionData.total);
        self.tableModel.set('loading', false);
        def.resolve(fetchedCollectionData);
      },
      (response) => {
        self.tableModel.set('loading', false);
        self.tableModel.set('collectionTotal', 0);
        self.collection.reset();
        Toastr.error(response.error);
        def.reject(response);
      },
    );

    return def.promise();
  },
}));
