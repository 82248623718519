define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/components/printing',
  'modules/shop.cash-register-retail/templates/dailyReports/layout.hbs',
  'modules/common/components/currency',
  'modules/upx/components/upx',
  'modules/common/components/locale',
  'modules/common/components/moment',
  'modules/admin/behaviors/loader',
  'modules/shop.common/components/mode',

  'modules/shop.cash-register-retail/components/toaster',

  'modules/shop.cash-register-retail/models/upx/DefaultShopConfiguration',
  'modules/shop.cash-register-retail/models/settings/receiptPrinter',

  'modules/shop.cash-register-retail/components/datepickk',
  'modules/shop.cash-register-retail/components/feature',
], (
  $, _, Backbone, PrintingComponent, Template, Currency, Upx, Locale, Moment, Loader, Mode,
  Toaster,
  ShopConfiguration, ReceiptPrinter,
  Datepickk, Feature,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'daily-view',

  ui: {
    dayPicker: '#day-picker',
    today: '#today',
    yesterday: '#yesterday',
    cashup: '#cashup',
    print: '#print',
    ticket: '[data-ui=ticket]',
  },

  events: {
    'click @ui.dayPicker': 'showDayPicker',
    'click @ui.today': 'todayClicked',
    'click @ui.yesterday': 'yesterdayClicked',
    'click @ui.cashup': 'cashupClicked',
    'click @ui.print': 'printClicked',
  },

  data: null,

  dayShowing: null,
  fromMoment: null,
  toMoment: null,

  initialize(options) {
    this.serverTime = options.serverTime;
    this.currency_iso3 = options.ledger.get('currency_iso3');

    // Start of the day
    this.hasStartOfTheDay = ShopConfiguration.has('start_of_the_day');

    // Check with the server time if today should be yesterday.
    this.setTodayIsYesterday();

    // Set initial date
    this.setFromMoment(new Date());
    this.setToMoment(new Date());

    this.behaviors = {
      Loader: {
        behaviorClass: Loader,
      },
    };

    this._behaviors = Marionette.Behaviors(this);
  },

  onShow() {
    // Today is default
    this.todayClicked();
  },

  setTodayIsYesterday() {
    const startOfTheDay = ShopConfiguration.getStartOfTheDay();
    const now = new Moment(this.serverTime.substring(0, this.serverTime.length - 6));
    const start = now.clone().set('hours', startOfTheDay.hours).set('minutes', startOfTheDay.minutes);

    this.todayIsYesterday = now.diff(start) < 0;
  },

  setFromMoment(date) {
    const moment = new Moment(date);

    if (!this.hasStartOfTheDay) {
      moment.startOf('day');
    }

    this.fromMoment = moment;
  },

  setToMoment(date) {
    const moment = new Moment(date);

    if (!this.hasStartOfTheDay) {
      moment.endOf('day');
    }

    this.toMoment = moment;
  },

  getFromMoment() {
    // clone to ensure the moment object is unedited
    return this.fromMoment.clone();
  },

  getToMoment() {
    // clone to ensure the moment object is unedited
    return this.toMoment.clone();
  },

  showDayPicker() {
    const datepicker = new Datepickk();

    datepicker.lang = Locale.getLocale();
    datepicker.button = Locale.translate('ok');
    datepicker.today = true;
    datepicker.maxSelections = 1;
    datepicker.onConfirm = () => {
      const [date] = datepicker.selectedDates;
      this.setFromMoment(date);
      this.setToMoment(date);
      this.loadData();
    };

    datepicker.selectDate(this.getFromMoment().toDate());
    datepicker.show();
  },

  getToday() {
    const moment = new Moment();

    // Subtract a day if today is not there yet
    if (this.todayIsYesterday) moment.subtract('day', 1);

    // Return
    return moment;
  },

  todayClicked() {
    const moment = this.getToday();

    this.setFromMoment(moment);
    this.setToMoment(moment);
    this.loadData();
  },

  yesterdayClicked() {
    const moment = this.getToday();
    moment.subtract('day', 1);

    this.setFromMoment(moment);
    this.setToMoment(moment);
    this.loadData();
  },

  cashupClicked() {
    this.loader.startLoader();
    const { ledger } = this.options;
    const parameters = {
      fields: {
        shop_ledger_snapshot: {
          shop_ledger_id: ledger.get('id'),
        },
      },
    };

    Upx.call('ShopModule', 'newVolatileCashDrawerSnapshot', parameters)
      .then((resp) => {
        this.triggerMethod('layout:swap', 'cashup', {
          snapshot: new Backbone.DeepModel(resp),
          ledger,
        });
      });
  },

  printClicked() {
    const def = this.loader.startLoader();

    const data = {
      start_date: this.getFromMoment(),
      end_date: this.getToMoment(),
      currency_iso3: this.currency_iso3,
      turnover_data: this.data,
    };

    PrintingComponent.printDailyTurnover(data).then(def.resolve, def.reject);
  },

  loadData() {
    const def = this.loader.startLoader();
    def.fail((resp) => Toaster.error(resp.error));

    const ledgerParameters = {
      fields: {
        shop_ledger_id: this.options.ledger.get('id'),
        date_from_incl: this.getFromMoment().format('YYYY-MM-DD'),
        date_to_incl: this.getToMoment().format('YYYY-MM-DD'),
      },
    };

    const taxRateParameters = {
      start: 0,
      limit: 1,
      filters: [{
        name: 'country_iso2',
        val: 'WO',
      }, {
        name: 'alias',
        val: 'special_emballage_packaging',
      }],
    };

    $.when(
      Upx.call('ShopModule', 'calculateShopLedgerTotal', ledgerParameters),
      Upx.call('ProductsModule', 'listTaxRates', taxRateParameters),
    ).then((ledgerResp, taxRateResp) => {
      this.emballageTaxRateId = taxRateResp.data[0].id;
      this.data = this.overwriteDefaults(ledgerResp.shop_ledger_snapshot_sums || {});

      // Only apply the date from the backend if there is a startOfTheDay
      // Reason being is that the backend returns the wrong date in date_to if there is no startOfTheDate
      if (this.hasStartOfTheDay) {
        this.setFromMoment(ledgerResp.date_from);
        this.setToMoment(ledgerResp.date_to);
      }

      if (!this.isDestroyed) this.render();
    }, def.reject);
  },

  overwriteDefaults(sums) {
    const defaults = {
      invoiced_with_tax: '0.00',
      invoiced_without_tax: '0.00',
      refunded_with_tax: '0.00',
      refunded_without_tax: '0.00',
      turnover_with_tax: '0.00',
      turnover_without_tax: '0.00',
      taxes: {},
      tax_total: '0.00',
      skimmed_total: '0.00',
      cash_difference_total: '0.00',
      other_difference_total: '0.00',
      discount_with_tax: '0.00',
      discount_percentage: '0',
      not_invoiced_with_tax: '0.00',
      not_invoiced_without_tax: '0.00',
      manual_mutation_in: '0.00',
      manual_mutation_out: '0.00',
      payment_in: '0.00',
      payment_out: '0.00',
      payment_total: '0.00',
      emballage_out: '0.00',
      payments: {
        cash: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
        terminal: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
        gift_cards: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
        external_gift_cards: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
        other: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
        web: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
        loyalty_points: {
          in: '0.00',
          out: '0.00',
          total: '0.00',
        },
      },
    };

    $.each(this.options.taxRates, (key, value) => {
      defaults.taxes[value] = '0.00';
    });

    $.each(sums, (key, sum) => {
      if (sum.type_alias === 'Tax') {
        if (sum.tax_rate_id === this.emballageTaxRateId) {
          // tax is emballage
          defaults.emballage_out = sum.tax_negative_total_wt;
        } else {
          // any other tax
          if (parseFloat(sum.tax) > 0) {
            defaults.taxes[parseFloat(sum.tax) * 100] = sum.balance_change;
          }
        }
      } else if (sum.type_alias === 'TaxTotal') {
        defaults.tax_total = sum.balance_change;
      } else if (sum.type_alias === 'Invoiced') {
        defaults.invoiced_with_tax = sum.balance_change;
      } else if (sum.type_alias === 'InvoicedWithoutTax') {
        defaults.invoiced_without_tax = sum.balance_change;
      } else if (sum.type_alias === 'Refunded') {
        defaults.refunded_with_tax = sum.balance_change;
      } else if (sum.type_alias === 'RefundedWithoutTax') {
        defaults.refunded_without_tax = sum.balance_change;
      } else if (sum.type_alias === 'Turnover') {
        defaults.turnover_with_tax = sum.balance_change;
      } else if (sum.type_alias === 'TurnoverWithoutTax') {
        defaults.turnover_without_tax = sum.balance_change;
      } else if (sum.type_alias === 'NotInvoiced') {
        defaults.not_invoiced_with_tax = sum.balance_change;
      } else if (sum.type_alias === 'NotInvoicedWithoutTax') {
        defaults.not_invoiced_without_tax = sum.balance_change;
      } else if (sum.type_alias === 'ManualAdd') {
        defaults.manual_mutation_in = sum.balance_change;
      } else if (sum.type_alias === 'ManualSubtract') {
        defaults.manual_mutation_out = sum.balance_change;
      } else if (sum.type_alias === 'CashSkimSubtract') {
        defaults.skimmed_total = sum.balance_change;
      } else if (sum.type_alias === 'DifferenceClearing') {
        defaults.cash_difference_total = sum.balance_change;
      } else if (sum.type_alias === 'DifferenceClearingOther') {
        defaults.other_difference_total = sum.balance_change;
      } else if (sum.type_alias === 'Discount') {
        defaults.discount_with_tax = sum.balance_change;
      } else if (sum.type_alias === 'DiscountPercentage') {
        defaults.discount_percentage = sum.balance_change;
      } else if (sum.type_alias === 'PaymentByType' || sum.type_alias === 'PaymentInByType' || sum.type_alias === 'PaymentOutByType') {
        const split = sum.type.split('::');
        const type = split[1];
        let subType = 'total';

        if (sum.type_alias === 'PaymentByType') {
          subType = 'total';
        } else if (sum.type_alias === 'PaymentInByType') {
          subType = 'in';
        } else if (sum.type_alias === 'PaymentOutByType') {
          subType = 'out';
        }

        if (type === 'Cash') {
          defaults.payments.cash[subType] = sum.balance_change;
        } else if (type === 'TerminalDevice') {
          defaults.payments.terminal[subType] = sum.balance_change;
        } else if (type === 'GiftCard') {
          defaults.payments.gift_cards[subType] = sum.balance_change;
        } else if (type === 'ExternalGiftCard') {
          defaults.payments.external_gift_cards[subType] = sum.balance_change;
        } else if (type === 'Web') {
          defaults.payments.web[subType] = sum.balance_change;
        } else if (type === 'Other') {
          defaults.payments.other[subType] = sum.balance_change;
        } else if (type === 'LoyaltyPoints') {
          defaults.payments.loyalty_points[subType] = sum.balance_change;
        }
      } else if (sum.type_alias === 'PaymentInTotal') {
        defaults.payment_in = sum.balance_change;
      } else if (sum.type_alias === 'PaymentOutTotal') {
        defaults.payment_out = sum.balance_change;
      } else if (sum.type_alias === 'PaymentTotal') {
        defaults.payment_total = sum.balance_change;
      }
    });

    return defaults;
  },

  onRender() {
    this.setColors();
  },

  setColors() {
    const lastColumns = this.$el.find('tbody > tr > td:not(:first-child)').not('.exclude-colorize');

    $.each(lastColumns, (key, element) => {
      const columnToColor = $(element);
      const value = columnToColor.html();
      const floated = Currency.currencyToFloat(value);

      if (floated > 0) {
        columnToColor.addClass('text-success');
      } else if (floated < 0) {
        columnToColor.addClass('text-danger');
      }
    });
  },

  isTodayShowing() {
    const now = new Moment(this.serverTime);
    return now.isBetween(
      this.getFromMoment(),
      this.getToMoment(),
    ) || now.isSame(this.getToMoment());
  },

  getFormatString() {
    const formatString = 'D MMM YYYY';

    // Return with time formatting
    if (this.hasStartOfTheDay) return `${formatString} H:mm`;

    // Return without time formatting
    return formatString;
  },

  shouldShowLoyaltyPoints() {
    let hasData = false;
    if (this.data && this.data.payments) {
      const positiveValues = _.filter(
        this.data.payments.loyalty_points,
        (loyaltyData) => parseInt(loyaltyData, 10) !== 0,
      );
      hasData = positiveValues.length > 0;
    }

    return Feature.isLoyaltyFeatureEnabled() || hasData;
  },

  serializeData() {
    const formatString = this.getFormatString();
    return {
      currency_iso3: this.currency_iso3,
      dayShowing: Moment(this.dayShowing).format('LL'),
      fromDate: this.getFromMoment().format(formatString),
      toDate: this.getToMoment().format(formatString),
      isToday: this.isTodayShowing(),
      hasReceiptPrinter: ReceiptPrinter.isWantedType(),
      showLoyaltyPoints: this.shouldShowLoyaltyPoints(),
      data: this.data,
    };
  },
}));
