define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/settingsCollection.hbs',

], (
  $, _, Backbone, Template,
) => Backbone.Marionette.CompositeView.extend({
  template: Template,

  childViewContainer: '[data-region="settings"]',

  getChildView(model) {
    return model.getSettingsViewCls();
  },

  childViewOptions(model) {
    return model.getSettingViewOptions({
      tableModel: this.tableModel,
    });
  },

  className: 'upx-table__dropdown themed-background-grey',

  initialize(options) {
    this.presetCollection = options.presetCollection;
    this.tableModel = options.tableModel;
  },

  ui: {
    reset: '[data-action="reset"]',
    toggle: '[data-action="toggle"]',
  },

  events: {
    'click @ui.reset': 'resetClicked',
    'click @ui.toggle': 'toggleClose',
    'click [data-item]': 'itemClicked',
  },

  resetClicked() {
    const self = this;
    this.collection.each((settingsModel, index) => {
      // only do silent false for the last model in the collection so it will trigger
      // the change of the labels (selected filters in header of table)
      const silent = self.collection.length - 1 !== index;
      settingsModel.resetValue(silent);
    });

    this.tableModel.set('settingsShown', !this.tableModel.get('settingsShown'));
    this.tableModel.set('settingsShown', !this.tableModel.get('settingsShown'));
  },

  itemClicked(ev) {
    const el = ev.currentTarget;
    const id = el.dataset.item;
    const model = this.presetCollection.get(id);
    if (model) {
      const fn = model.get('fn');
      fn();
      this.toggleClose();
    }
  },

  toggleClose() {
    this.tableModel.set('settingsShown', !this.tableModel.get('settingsShown'));
  },

  onShow() {
    this.toggleView();
    this.tableModel.on('change:settingsShown', this.toggleView, this);
  },

  toggleView() {
    if (this.tableModel.get('settingsShown')) {
      this.$el.show();
    } else {
      this.$el.hide();
    }
  },

  getHasSettingsFilter() {
    return !!this.collection.findWhere({ setting: true });
  },

  serializeData() {
    return {
      hasSettingsFilter: this.getHasSettingsFilter(),
      hasPresets: this.presetCollection.length > 0,
      presets: this.presetCollection.toJSON(),
    };
  },
}));
