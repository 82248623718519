define([
  'backbone',
  'modules/shop.cash-register-retail/views/customers/create',
  'modules/shop.cash-register-retail/templates/customers/edit',

  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/toaster',
  'modules/common/components/moment',
  'modules/common/components/historyBreadcrumb',

  'upx.modules/ShopModule/models/ShopRelation',
  'upx.modules/RelationsModule/models/Relation',
  'modules/shop.cash-register-retail/components/email.js',

  'modules/shop.cash-register-retail/views/popups/messagePopup',

], (Backbone, View, Template,
  Locale, Toaster, Moment, HistoryBreadcrumb,
  ShopRelationModel, RelationModel, Email,
  MessagePopupView) => View.extend({

  template: Template,

  saveClicked() {
    this.prepareModel();
    if (!this.model.isValid(true)) {
      return;
    }
    const self = this;
    const model = new RelationModel(this.model.get('relation'));
    // set missing pk data_id
    model.set('data_id', model.get('id'));

    if (model.isValid(true)) {
      const def = this.loader.startLoader();
      model.save()
        .then(() => {
          def.resolve();
        }, (response) => {
          def.reject(response);
        });

      def.then(() => {
        Toaster.info(Locale.translate('successfully_updated_customer'));
        this.relationModel.set(model.toJSON());
        self.triggerMethod('layout:swap');
      },
      // fails
      (response) => {
        let message = Locale.translate('something_went_wrong_while_updating_customer');
        if ('error' in response) {
          message = response.error;
        }
        if (response.error === 'Subuser with this email already exists') {
          message = Locale.translate('customer_with_this_email_already_exists');
        }
        const view = new MessagePopupView();
        view.open(message);
      });
    }
  },

  backClicked() {
    HistoryBreadcrumb.goBack({ skipSameRoute: false });
  },

  initialize(options) {
    this.relationModel = options.model;
    this.model = new ShopRelationModel({
      relation: this.relationModel.toJSON(),
    });

    const email = Email.cleanEmptyEmail(this.model.get('relation.contact_set.email'));
    if (!email) {
      this.model.unset('relation.contact_set.email');
    }

    if (this.model.has('relation.contact_person.birthdate')) {
      const birthdate = new Moment(this.model.get('relation.contact_person.birthdate'));
      this.model.set({
        birthdate: {
          day: birthdate.date(),
          month: birthdate.month() + 1,
          year: birthdate.year(),
        },
      });
    } else {
      this.model.set({
        birthdate: {
          day: 0,
          month: 0,
          year: 0,
        },
      });
    }
  },
}));
