define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './layout',

  'modules/shop.cash-register-retail/collections/employeeCalendar',
  'modules/shop.cash-register-retail/collections/calendarItemType',
], (
  $, _, Backbone, SwappableView,
  OverviewView,
  EmployeeCalendarCollection, CalendarItemTypeCollection,
) => SwappableView.extend({

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);
    this.setMainView(OverviewView, {
      model: options.model,
    });
  },

  loadSharedData(dataStorage) {
    return $.when(
      EmployeeCalendarCollection.load(),
      CalendarItemTypeCollection.load(),
    );
  },

}));
