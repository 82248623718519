define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/views/checkoutButtons/item',
  'modules/shop.cash-register-retail/collections/checkoutButtons',

  'modules/admin/behaviors/loader',
], (
  $, _, Backbone,
  ChildView, CheckoutButtonsCollection, Loader,
) => Backbone.Marionette.CollectionView.extend({

  className: 'full-child-height default-products',
  collection: CheckoutButtonsCollection,

  childViewOptions(model, index) {
    return {
      model,
    };
  },
  getChildView(model) {
    if (model.get('type_alias') === 'ShopModule::DashboardButtonEmpty') {
      return Backbone.Marionette.ItemView.extend({
        className: 'full-child-height',
        template: _.template(''),
      });
    }
    return ChildView;
  },

  emptyView: Backbone.Marionette.ItemView.extend({
    className: 'full-child-height',
    template: _.template(''),
    behaviors: {
      Loader: {
        behaviorClass: Loader,
      },
    },

    onShow() {
      const def = this.loader.startLoader();
      $.when(CheckoutButtonsCollection.getLoadDef()).then(def.resolve, def.reject);
    },
  }),
}));
