define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/outstandingInvoices/collection',

  './collectionItem',
  './collectionEmpty',

  'modules/admin/behaviors/loader',
  'modules/common/components/historyBreadcrumb',
], (
  $, _, Backbone, Template,
  ItemView, EmptyView,
  Loader, HistoryBreadcrumb,
) => Backbone.Marionette.CompositeView.extend({

  className: 'pos-page-content customer-outstanding-invoices',

  template: Template,

  emptyView: EmptyView,

  childView: ItemView,

  childViewContainer: 'tbody',

  behaviors: {
    Loader: {
      behaviorClass: Loader,
    },
  },

  events: {
    'click [data-action=back]': 'backClicked',
  },

  initialize({ model }) {
    this.model = model;
  },

  backClicked() {
    HistoryBreadcrumb.goBack({ skipSameRoute: false });
  },

}));
