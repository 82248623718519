define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/customers/selection/searching',

  'modules/shop.cash-register-retail/views/popups/customerInvoicePopup/layout',

  'modules/shop.cash-register-retail/components/membercard',
  'modules/shop.common/components/onScreenKeyboard',

  'modules/shop.cash-register-retail/events/app/fullScreenLoader',
  'modules/common/components/locale',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/components/customer',
  'modules/shop.cash-register-retail/models/upx/LoyaltyProgram',
  'upx.modules/ShopModule/models/LoyaltyCustomer',

  'upx.modules/BillingModule/models/InvoiceStat',
  'upx.modules/RelationsModule/collections/DataNote',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
  'modules/common/components/currency',
  'modules/upx/components/upx',
  'modules/shop.cash-register-retail/components/feature',

  'modules/shop.cash-register-retail/views/popups/confirmPopup',
  'modules/shop.cash-register-retail/views/popups/simplePopup',
], (
  $, _, Backbone, Template,
  CustomerInvoicePopup,
  MembercardComponent, OnScreenKeyboard,
  FullScreenLoaderEvent, Locale,
  KeyboardModel, CustomerComponent, LoyaltyProgramModel, LoyaltyCustomerModel,
  InvoiceStatModel, DataNoteCollection, OrderItemCollection, Currency, Upx, Feature,
  ConfirmPopupView, SimplePopupView,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'block full customer-searching designer-box rounded',

  ui: {
    input: 'input',
  },

  events: {
    'submit [data-action="form"]': 'customerSearchSubmit',
  },

  initialize(options) {
    this.showInvoicePopup = !('showInvoicePopup' in options) || options.showInvoicePopup;
    this.showPermanentDiscountPopup = !('showPermanentDiscountPopup' in options) || options.showPermanentDiscountPopup;
    this.applyPermanentDiscountPopup = !('applyPermanentDiscountPopup' in options) || options.applyPermanentDiscountPopup;
    this.orderItemCollection = options.orderItemCollection || OrderItemCollection;
  },
  onShow() {
    KeyboardModel.on('change:mode', this.modeChange, this);
    KeyboardModel.customerSearchMode(this.cid);
  },

  onDestroy() {
    KeyboardModel.resetMode();
    KeyboardModel.off('change:mode', this.modeChange, this);
  },

  modeChange() {
    if (KeyboardModel.get('mode') === KeyboardModel.MODE_CUSTOMER_SEARCH) {
      this.startListening();
    } else {
      this.stopListening();
    }
  },

  startListening() {
    KeyboardModel.on('key:pressed', this.handleKeyPress, this);
    KeyboardModel.on('key:confirm', this.handleConfirmation, this);
    KeyboardModel.on('key:backspace', this.handleBackspace, this);
    KeyboardModel.on('key:cancel', this.handleCancel, this);
    this.$el.addClass('element-focus');
  },

  stopListening() {
    KeyboardModel.off('key:pressed', this.handleKeyPress, this);
    KeyboardModel.off('key:confirm', this.handleConfirmation, this);
    KeyboardModel.off('key:backspace', this.handleBackspace, this);
    KeyboardModel.off('key:cancel', this.handleCancel, this);
    this.$el.removeClass('element-focus');
    this.triggerMethod('layout:swap');
  },

  handleKeyPress(key) {
    if (!OnScreenKeyboard.isShown() && !this.ui.input.is(':focus')) {
      const el = this.ui.input;
      if (!_.isString(el)) {
        let value = el.val();
        value += key;
        el.val(value);
      }
    }
  },

  handleConfirmation() {
    this.search();
  },

  handleBackspace() {
    if (!OnScreenKeyboard.isShown()) {
      const el = this.ui.input;
      let value = el.val();
      value = value.slice(0, -1);
      el.val(value);
    }
  },

  handleCancel() {
    this.triggerMethod('layout:swap');
  },

  customerSearchSubmit(event) {
    event.preventDefault();
    this.search();
  },

  search: _.debounce(function () {
    // Get and trim the query
    const query = this.ui.input.val();
    if (_.isString(query)) query.trim();

    // Reset input
    this.ui.input.val('');

    if (query) {
      const def = new $.Deferred();

      (new FullScreenLoaderEvent({
        deferred: def,
        title: Locale.translate('searching_customer'),
      })).trigger();

      MembercardComponent.searchMember(query)
        .then((model) => {
          if (model) {
            CustomerComponent.getPermanentDiscount({
              relation_data_id: model.get('id'),
            }).then((permanentDiscount) => {
              model.set('permanent_discount', permanentDiscount);
              if (Feature.isLoyaltyFeatureEnabled()) {
                LoyaltyProgramModel.fetchLoyaltyCustomer(model.get('id')).then(
                  (data) => {
                    model.set('loyalty_customer', data);
                    this.model.updateFromModel(model);
                    this.triggerMethod('layout:swap', 'selected');
                    this.customerCheck().then(def.resolve, def.reject);
                  },
                  def.reject,
                );
              } else {
                this.model.updateFromModel(model);
                this.triggerMethod('layout:swap', 'selected');
                this.customerCheck().then(def.resolve, def.reject);
              }
            });
          } else {
            def.reject();
          }
        }, def.reject);
    }
  }, 100),

  customerCheck() {
    const def = new $.Deferred();

    const discountDef = new $.Deferred();
    const discountPercentage = this.model.get('permanent_discount');
    if (this.showPermanentDiscountPopup && discountPercentage) {
      if (this.applyPermanentDiscountPopup && this.orderItemCollection.length > 0) {
        const view = new ConfirmPopupView();
        view.open(
          Locale.translate('this_customer_has_{percentage}percent_permanent_discount', {
            percentage: discountPercentage,
          }),
          Locale.translate(
            'do_you_want_to_apply_{percentage}percent_discount_to_all_products_which_are_currently_in_the_checkout',
            {
              percentage: discountPercentage,
            },
          ),
        ).then(() => {
          this.orderItemCollection.applyPercentageDiscountToAllItemsWithConfirmation(
            Currency.toCurrency(discountPercentage),
          ).always(discountDef.resolve);
        }, discountDef.reject);
      } else {
        const view = new SimplePopupView({
          headerText: Locale.translate('this_customer_has_{percentage}percent_permanent_discount', {
            percentage: discountPercentage,
          }),
          content: Locale.translate('if_you_want_to_apply_this_discount_to_full_order_please_fo_back_to_checkout_screen'),
          footerConfirmEnabled: false,
          footerCancelEnabled: false,
        });
        view.on('popup:cancel', () => {
          view.close();
          discountDef.resolve();
        });
        view.open();
      }
    } else {
      discountDef.resolve();
    }
    if (this.showInvoicePopup) {
      $.when(
        discountDef,
        this.getInvoiceStats(),
        this.hasCustomerNotes(),
      ).then((invoiceStatModel, customerHasNotes) => {
        def.resolve();
        setTimeout(() => {
          this.showCustomerInvoicePopup(invoiceStatModel, customerHasNotes, discountPercentage);
        }, 50); // allow other popups to close
      }, def.reject);
    } else {
      $.when(discountDef).then(def.resolve, def.reject);
    }

    return def;
  },

  getInvoiceStats() {
    const def = new $.Deferred();

    const invoiceStatModel = new InvoiceStatModel();
    const parameters = {
      start: 0,
      limit: 1,
      filters: [
        {
          name: 'relation_data_to_id__=',
          val: this.model.get('id'),
        },
        {
          name: 'is_paid__=',
          val: 0,
        },
      ],
    };

    invoiceStatModel.listByRelationTo(parameters)
      .then((resp) => {
        const { data, count } = resp;
        if (count) {
          def.resolve(new InvoiceStatModel(_.first(data)));
        } else {
          def.resolve(false);
        }
      }, def.reject);

    return def;
  },

  hasCustomerNotes() {
    const def = new $.Deferred();

    const collection = new DataNoteCollection();
    const params = {
      start: 0,
      limit: 1,
      sort: [],
      filters: [{
        name: 'about/id__=',
        val: this.model.get('id'),
      }],
    };

    collection.fetch({ params })
      .then(() => def.resolve(collection.length > 0), def.reject);

    return def;
  },

  showCustomerInvoicePopup(invoiceStatModel, customerHasNotes, discountPercentage = null) {
    if (invoiceStatModel || customerHasNotes) {
      const view = new CustomerInvoicePopup({
        invoiceStatModel,
        customerHasNotes,
        customerRelationDataId: this.model.get('id'),
        discountPercentage,
      });
      view.open();
    }
  },

}));
