define([
  'jquery',
  'underscore',
  'backbone',
  'modules/common/views/swappableWithLoading',

  './search',
  './searching',
  './selected',

  'modules/shop.cash-register-retail/models/selectedCustomer',
  'modules/shop.cash-register-retail/collections/currentOrderItem',
], (
  $, _, Backbone, SwappableView,
  SearchView, SearchingView, SelectedView,
  SelectedCustomerModel, OrderItemCollection,
) => SwappableView.extend({

  className: 'customer-selection',

  initialize(options) {
    SwappableView.prototype.initialize.call(this, options);

    options.showInvoicePopup = !('showInvoicePopup' in options) || options.showInvoicePopup;
    options.showPermanentDiscountPopup = !('showPermanentDiscountPopup' in options) || options.showPermanentDiscountPopup;
    options.applyPermanentDiscountPopup = !('applyPermanentDiscountPopup' in options) || options.applyPermanentDiscountPopup;
    options.orderItemCollection = options.orderItemCollection || OrderItemCollection;

    if (!this.model) {
      this.model = SelectedCustomerModel;
    }

    this.setMainView(SearchView);
    this.setView('searching', SearchingView, {
      model: this.model,
      showInvoicePopup: options.showInvoicePopup,
      showPermanentDiscountPopup: options.showPermanentDiscountPopup,
      applyPermanentDiscountPopup: options.applyPermanentDiscountPopup,
      orderItemCollection: options.orderItemCollection,
    });
    this.setView('selected', SelectedView, {
      model: this.model,
      allowChange: options.allowChange,
    });

    if (this.model.has('id')) {
      this.options.swapTo = 'selected';
    }

    this.listenTo(this.model, 'change:id', _.debounce(() => this.onCustomerChange(), 5));
  },

  onCustomerChange() {
    if (this.model.has('id')) {
      this.swapView('selected');
    } else {
      this.swapView();
    }
  },

  template() {
    return '<div js-region="swappable-item" class="full-child-height"></div>';
  },

}));
