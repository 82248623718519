define([
  'jquery',
  'underscore',
  'backbone',
  'application',
  'modules/shop.cash-register-retail/templates/error',
  'modules/shop.cash-register-retail/views/popups/messagePopup',
], (
  $, _, Backbone, App, Template, MessagePopup,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  regions: {
    error: '[data-region=error]',
  },

  onRender() {
    const region = this.getRegion('error');
    const view = new MessagePopup();
    const def = new $.Deferred();
    const { redirect } = this.options;
    region.show(view);
    view.open(this.options.error, def);
    def.then(() => {
      if (redirect) {
        Backbone.history.navigate(redirect, { trigger: true });
      } else {
        Backbone.history.navigate('checkout', { trigger: true });
      }
    });
  },

}));
