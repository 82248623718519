define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/checkouts/retail.hbs',

  'modules/shop.cash-register-retail/views/customers/selection/swappable',
  'modules/shop.cash-register-retail/views/checkoutButtons/collection',
  'modules/shop.cash-register-retail/views/keypads/main',

  'modules/shop.cash-register-retail/views/products/totals',
  'modules/shop.cash-register-retail/views/productListWithSearch/layout',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/shop.cash-register-retail/views/popups/orderMemoryPopup',
  'modules/shop.cash-register-retail/models/settings/shopPos',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/shop.cash-register-retail/collections/orderMemory',
  'modules/shop.cash-register-retail/components/orderMemory',
  'modules/shop.cash-register-retail/components/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/models/ccv/openCCVPinTransaction',
  'modules/shop.cash-register-retail/models/ccv/ccvPin',
], (
  $, _, Backbone, Template,
  CustomerSelectionView, ProductDefaultsView, KeypadPercentageView,
  ProductTotalsView, ProductListAndSearchView,
  KeyboardModel, Locale, OrderItemCollection,
  OrderMemoryPopup, ShopPos,
  CashRegisterApi, OrderMemoryCollection, OrderMemoryComponent, PointOfSale, PaymentMethods,
  OpenCCVPinTransaction, CCVPin,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'checkout',

  regions: {
    customer: '[data-region="customer"]',
    checkoutButtons: '[data-region="checkoutButtons"]',
    keypad: '[data-region="keypad"]',
    popup: '[data-region="popup"]',
    productSearch: '[data-region="product-search"]',

    productListAndSearch: '[data-region="product-list-and-search"]',
    'product-list': '[data-region="product-list"]',
    'product-totals': '[data-region="product-totals"]',
    'product-description': '[data-region="product-description"]',
  },

  ui: {
    bottomButtons: '.pay',
    checkoutBtn: '[data-ui="checkout"]',
    payBtn: '[data-ui="pay"]',
    memoryCount: '[data-ui="memory"]>span',
    productDescription: '[data-ui="product-description"]',
  },

  events: {
    'click [data-action="checkout"]': 'checkoutClicked',
    'click [data-action="pay"]': 'payClicked',
    'click [data-action="memory"]': 'memoryClicked',
  },

  checkoutClicked: _.debounce((e) => {
    if (PointOfSale.hasPointOfSale()) {
      CashRegisterApi.logAction('VIEW_BUTTON_CLICKED', {
        button: 'checkout',
      });
      Backbone.history.navigate('payment', { trigger: true });
    }
  }, 10),

  payClicked: _.debounce((e) => {
    if (OpenCCVPinTransaction.hasOpenTransaction()) {
      CCVPin.openOpenTransactionPopup();
      return;
    }

    if (PointOfSale.hasPointOfSale()) {
      CashRegisterApi.logAction('VIEW_BUTTON_CLICKED', {
        button: 'pay',
      });
      Backbone.history.navigate('quick-payment', { trigger: true });
    }
  }, 10),

  memoryClicked: _.debounce((e) => {
    $(e.currentTarget).blur();
    CashRegisterApi.logAction('VIEW_BUTTON_CLICKED', {
      button: 'memory',
    });

    OrderMemoryComponent.storeCurrent();

    const view = new OrderMemoryPopup();
    view.open();
  }, 10),

  onRender() {
    if (!ShopPos.isQuickPinEnabled()) {
      this.ui.bottomButtons.addClass('quick-pay-disabled');
    }
    this.renderPayBtnStatus();
    this.renderCustomer();
    this.renderProductDefaults();
    this.renderProductTotals();
    this.renderKeypad();
    this.renderMemoryOrderCount();
    this.renderProductListAndSearch();
  },

  renderMemoryOrderCount: _.debounce(function () {
    if (!this.isDestroyed) {
      // || "0" needed otherwise empty string is rendered
      this.ui.memoryCount.text(
        `(${Locale.translate('count_{0}', OrderMemoryCollection.length || '0')})`,
      );
    }
  }, 10),

  renderCustomer() {
    const region = this.getRegion('customer');
    const view = new CustomerSelectionView();
    region.show(view);
  },

  renderProductDefaults() {
    const region = this.getRegion('checkoutButtons');
    const view = new ProductDefaultsView();
    region.show(view);
  },

  renderProductListAndSearch() {
    const region = this.getRegion('productListAndSearch');
    const view = new ProductListAndSearchView({
      checkoutBtn: this.ui.checkoutBtn,
    });
    region.show(view);
  },

  renderProductTotals() {
    const region = this.getRegion('product-totals');
    const view = new ProductTotalsView({
      isEditable: true,
    });
    region.show(view);
  },

  renderKeypad() {
    const region = this.getRegion('keypad');
    const view = new KeypadPercentageView();
    region.show(view);
  },

  onShow() {
    OrderItemCollection.on('all', this.renderPayBtnStatus, this);
    OrderMemoryCollection.on('all', this.renderMemoryOrderCount, this);
    OpenCCVPinTransaction.on('transaction:changed', this.renderPayBtnStatus, this);
  },

  onDestroy() {
    OrderItemCollection.off('all', this.renderPayBtnStatus, this);
    OrderMemoryCollection.off('all', this.renderMemoryOrderCount, this);
    OpenCCVPinTransaction.off('transaction:changed', this.renderPayBtnStatus, this);
  },

  isPayButtonEnabled() {
    const loadingProducts = OrderItemCollection.where({ type: OrderItemCollection.TYPE_LOADER });
    return !KeyboardModel.isModeWithConfirmation() && OrderItemCollection.length > 0 && loadingProducts.length === 0;
  },

  // debounce needed to make sure the button updates while events are still firing
  renderPayBtnStatus: _.debounce(function () {
    // Has issues with onDestroy event triggering on the OrderItemCollection collection, that causes this to update
    // When there view is loading or unloaded. and makes the line error out.
    if (!_.isString(this.ui.checkoutBtn)) {
      const disabled = !this.isPayButtonEnabled();
      this.ui.checkoutBtn.attr('disabled', disabled);
      const quickPinPayEnabled = (
        PaymentMethods.pinEnabled()
        && this.isPayButtonEnabled()
        && OrderItemCollection.getTotalPriceWt() >= 0
      );
      this.ui.payBtn.attr('disabled', !quickPinPayEnabled);

      if (OpenCCVPinTransaction.hasOpenTransaction()) {
        this.ui.payBtn.addClass('btn-danger').removeClass('btn-success');
      } else {
        this.ui.payBtn.addClass('btn-success').removeClass('btn-danger');
      }
    }
  }, 50),

}));
