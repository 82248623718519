define([
  'jquery',
  'underscore',
  'backbone',

  'modules/shop.cash-register-retail/collections/currentOrderItem',

  './collectionItem',
], (
  $, _, Backbone,
  OrderItemCollection,
  ItemView,
) => Backbone.Marionette.CollectionView.extend({

  childView: ItemView,

  className: 'customer-screen-products table table-borderless',

  tagName: 'table',

  initialize() {
    this.collection = OrderItemCollection;
  },

  // Sort item by time, where the newest is at the top
  viewComparator(a, b) {
    const t1 = a.get('time');
    const t2 = b.get('time');
    if (t1 < t2) {
      return 1;
    } if (t1 > t2) {
      return -1;
    }
    return 0;
  },

}));
