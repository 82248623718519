define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/table/bodyRowEmpty.hbs',
], (
  $, _, Backbone, Template,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  tagName: 'tr',

  initialize(options) {
    this.tableModel = options.tableModel;
    this.settingCollection = options.settingCollection;
  },

  onShow() {
    this.tableModel.on('change', this.render, this);
  },

  onDestroy() {
    this.tableModel.off('change', this.render, this);
  },

  isFiltered() {
    let isFiltered = false;
    this.settingCollection.each((settingModel) => {
      if (!isFiltered && settingModel.isValidFilter()) {
        isFiltered = true;
      }
    });
    return isFiltered;
  },

  isSearching() {
    return !!this.tableModel.get('search');
  },

  serializeData() {
    return {
      colspan: this.collection.length,
      noDataText: this.tableModel.get('noDataText'),
      noDataLink: this.tableModel.get('noDataLink'),
      noFilteredDataText: this.tableModel.get('noFilteredDataText'),
      noFilteredDataLink: this.tableModel.get('noFilteredDataLink'),
      noSearchResultsText: this.tableModel.get('noSearchResultsText'),
      noSearchResultsLink: this.tableModel.get('noSearchResultsLink'),
      search: this.tableModel.get('search'),
      isFiltered: this.isFiltered(),
      isSearching: this.isSearching(),
    };
  },

}));
