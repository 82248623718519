define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/checkouts/hospitality.hbs',

  'modules/shop.cash-register-retail/models/keyboard',
  'modules/shop.cash-register-retail/models/settings/shopPos',

  'modules/shop.cash-register-retail/collections/currentOrderItem',

  'modules/shop.cash-register-retail/views/products/list/layout',
  'modules/shop.cash-register-retail/views/products/description/layout',
  'modules/shop.cash-register-retail/views/products/totals',
  'modules/shop.cash-register-retail/views/products/blocks/swappable',
  'modules/shop.cash-register-retail/views/tabs/button',
  'modules/shop.cash-register-retail/views/checkoutButtons/cardButtons',

  'modules/shop.cash-register-retail/views/tables/button',
  'modules/shop.cash-register-retail/views/tables/addButton',

  'modules/shop.cash-register-retail/views/popups/keypadPopup',

  'modules/shop.cash-register-retail/components/cashRegisterApi',
  'modules/common/components/locale',
  'modules/shop.cash-register-retail/components/pointOfSale',
  'modules/shop.cash-register-retail/models/settings/paymentMethods',

  'modules/shop.cash-register-retail/models/ccv/openCCVPinTransaction',
  'modules/shop.cash-register-retail/models/ccv/ccvPin',
], (
  $, _, Backbone, Template,
  KeyboardModel, ShopPos,
  OrderItemCollection,
  ProductListView, ProductDescriptionView, ProductTotalsView, ProductBlockView, TabButtonView, CardButtonsView,
  TableButtonView, AddTableButtonView,
  KeypadPopupView,
  CashRegisterApi, Locale, PointOfSale, PaymentMethods,
  OpenCCVPinTransaction, CCVPin,
) => Backbone.Marionette.LayoutView.extend({

  template: Template,

  className: 'checkout-hospitality',

  ui: {
    bottomButtons: '.pay',
    checkoutBtn: '[data-ui="checkout"]',
    payBtn: '[data-ui="pay"]',
    productList: '[data-ui="product-list"]',
    productDescription: '[data-ui="product-description"]',
    nowBtn: '[data-ui=now]',
    laterBtn: '[data-ui=later]',
    tableButton: '[data-ui=table-button]',
  },

  regions: {
    'product-blocks-container': '[data-region=product-blocks-container]',
    'product-list': '[data-region=product-list]',
    'product-description': '[data-region="product-description"]',
    'product-totals': '[data-region="product-totals"]',
    'table-button': '@ui.tableButton',
    'card-buttons': '[data-region="card-buttons"]',

    popup: '[data-region=popup]',
  },

  events: {
    'click [data-action="checkout"]': 'checkoutClicked',
    'click [data-action="pay"]': 'payClicked',
    'click [data-action="tables"]': 'tabledClicked',

    'touchend @ui.nowBtn': 'nowClicked',
    'click @ui.nowBtn': 'nowClicked',
    'touchend @ui.laterBtn': 'laterClicked',
    'click @ui.laterBtn': 'laterClicked',
  },

  childEvents: {
    'discount:clicked': 'discountClicked',
  },

  MODE_NOW: 'mode-now',
  MODE_LATER: 'mode-later',

  initialize({ tableId }) {
    this.tableId = tableId;
    this.model = new Backbone.Model({
      mode: this.MODE_NOW,
    });
  },

  nowClicked: _.debounce(function () {
    this.toggleNowActive(true);
    this.model.set('mode', this.MODE_NOW);
    this.renderPayBtnStatus();
  }, 100),

  laterClicked: _.debounce(function () {
    this.toggleNowActive(false);
    this.model.set('mode', this.MODE_LATER);
    this.renderPayBtnStatus();
  }, 100),

  toggleNowActive(nowActive) {
    const activeClass = 'btn-primary';
    const inactiveClass = 'btn-default btn-alt';

    if (nowActive) {
      this.ui.nowBtn.addClass(activeClass);
      this.ui.nowBtn.removeClass(inactiveClass);

      this.ui.laterBtn.addClass(inactiveClass);
      this.ui.laterBtn.removeClass(activeClass);
    } else {
      this.ui.nowBtn.addClass(inactiveClass);
      this.ui.nowBtn.removeClass(activeClass);

      this.ui.laterBtn.addClass(activeClass);
      this.ui.laterBtn.removeClass(inactiveClass);
    }
  },

  tabledClicked(ev) {
    ev.currentTarget.blur();
    Backbone.history.navigate('tables', { trigger: true });
  },

  checkoutClicked: _.debounce((e) => {
    if (PointOfSale.hasPointOfSale()) {
      CashRegisterApi.logAction('VIEW_BUTTON_CLICKED', {
        button: 'checkout',
      });
      Backbone.history.navigate('payment', { trigger: true });
    }
  }, 10),

  payClicked: _.debounce((e) => {
    if (OpenCCVPinTransaction.hasOpenTransaction()) {
      CCVPin.openOpenTransactionPopup();
      return;
    }

    if (PointOfSale.hasPointOfSale()) {
      CashRegisterApi.logAction('VIEW_BUTTON_CLICKED', {
        button: 'pay',
      });
      Backbone.history.navigate('quick-payment', { trigger: true });
    }
  }, 10),

  renderKeypad: _.debounce(() => {
    const view = new KeypadPopupView();
    view.open('left');
  }, 10),

  discountClicked() {
    this.renderKeypad();
  },

  onRender() {
    if (!ShopPos.isQuickPinEnabled()) {
      this.ui.bottomButtons.addClass('quick-pay-disabled');
    }
    this.renderCardButtons();
    this.renderOrderList();
    this.renderOrderTotals();
    this.renderProductBlocks();
    this.renderPayBtnStatus();

    // TODO [ian@8/13/20]: Remove when tabs are being removed
    if (ShopPos.get('hospitality_tabs')) {
      this.renderTabsButton();
    } else if (this.tableId) {
      this.renderTableButton();
    } else {
      this.renderTablesButton();
    }
  },

  renderOrderList() {
    const region = this.getRegion('product-list');
    const view = new ProductListView({
      hideHeaders: true,
      showUnitPriceBelowDescription: true,
      columns: [
        'quantity',
        'description',
        'price',
        'delete',
      ],
      onKeyPadOpen: () => this.renderKeypad(),
    });
    region.show(view);

    view.on('product:clicked', _.debounce((product) => {
      this.swapProductDescription(product);
    }, 100));

    view.on('product:needs-weight', _.debounce((product) => {
      this.swapProductDescription(product);
    }, 100));

    view.on('product:needs-description', _.debounce((product) => {
      this.swapProductDescription(product);
    }, 100));
  },

  swapProductDescription(productModel) {
    this.ui.productDescription.show();
    this.ui.productList.hide();
    KeyboardModel.setNeutralMode();
    const region = this.getRegion('product-description');
    const view = new ProductDescriptionView({
      model: productModel,
      hideMeasurements: true,
    });
    region.show(view);

    const self = this;
    view.on('view:closed', () => {
      self.ui.productList.show();
      self.ui.productDescription.hide();
      KeyboardModel.resetMode();
      region.reset();
    });
  },

  renderProductBlocks() {
    const region = this.getRegion('product-blocks-container');
    const view = new ProductBlockView({
      checkoutBtn: this.ui.checkoutBtn,
    });
    region.show(view);
  },

  renderTablesButton() {
    const region = this.getRegion('table-button');
    const view = new TableButtonView({
      printProductionReceipts: this.model.get('mode') === this.MODE_NOW,
    });
    region.show(view);
  },

  renderTableButton() {
    const region = this.getRegion('table-button');
    const view = new AddTableButtonView({
      tableId: this.tableId,
      printProductionReceipts: this.model.get('mode') === this.MODE_NOW,
    });
    region.show(view);

    this.ui.checkoutBtn.hide();
    this.ui.tableButton.css('grid-column', 'span 2');
  },

  // TODO [ian@8/13/20]: Remove when tabs are being removed
  renderTabsButton() {
    const region = this.getRegion('table-button');
    const view = new TabButtonView();
    region.show(view);
  },

  renderOrderTotals() {
    const region = this.getRegion('product-totals');
    const view = new ProductTotalsView({
      isEditable: true,
    });
    region.show(view);
  },

  renderCardButtons() {
    const region = this.getRegion('card-buttons');
    const view = new CardButtonsView();
    region.show(view);
  },

  onShow() {
    OrderItemCollection.on('all', this.renderPayBtnStatus, this);
    KeyboardModel.on('all', this.renderPayBtnStatus, this);
    if (this.tableId) {
      this.model.on('change:mode', this.renderTableButton, this);
    } else {
      this.model.on('change:mode', this.renderTablesButton, this);
    }
    OpenCCVPinTransaction.on('transaction:changed', this.renderPayBtnStatus, this);
  },

  onDestroy() {
    OrderItemCollection.off('all', this.renderPayBtnStatus, this);
    KeyboardModel.off('all', this.renderPayBtnStatus, this);
    if (this.tableId) {
      this.model.off('change:mode', this.renderTableButton, this);
    } else {
      this.model.off('change:mode', this.renderTablesButton, this);
    }
    OpenCCVPinTransaction.off('transaction:changed', this.renderPayBtnStatus, this);
  },

  isPayButtonEnabled() {
    const noConfirmation = !KeyboardModel.isModeWithConfirmation();
    const hasOrderItems = OrderItemCollection.length > 0;
    const hasLoadingItems = OrderItemCollection.where({ type: OrderItemCollection.TYPE_LOADER }).length > 0;
    const modeIsNow = this.model.get('mode') === this.MODE_NOW;

    return noConfirmation && hasOrderItems && !hasLoadingItems && modeIsNow;
  },

  // debounce needed to make sure the button updates while events are still firing
  renderPayBtnStatus: _.debounce(function () {
    // Has issues with onDestroy event triggering on the OrderItemCollection collection, that causes this to update
    // When there view is loading or unloaded. and makes the line error out.
    if (!_.isString(this.ui.checkoutBtn)) {
      const disabled = !this.isPayButtonEnabled();
      this.ui.checkoutBtn.attr('disabled', disabled);
      const quickPinPayEnabled = (
        PaymentMethods.pinEnabled()
          && this.isPayButtonEnabled()
          && OrderItemCollection.getTotalPriceWt() >= 0
      );
      this.ui.payBtn.attr('disabled', !quickPinPayEnabled);

      if (OpenCCVPinTransaction.hasOpenTransaction()) {
        this.ui.payBtn.addClass('btn-danger').removeClass('btn-success');
      } else {
        this.ui.payBtn.addClass('btn-success').removeClass('btn-danger');
      }
    }
  }, 50),

  serializeData() {
    return {
      hospitalityTabs: ShopPos.get('hospitality_tabs'),
    };
  },

}));
