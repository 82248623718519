define([
  'jquery',
  'underscore',
  'backbone',
  'modules/shop.cash-register-retail/templates/datatables/layout.hbs',

  'modules/common/components/string',

  // Regions
  'modules/shop.cash-register-retail/views/datatables/presets',

  'modules/shop.cash-register-retail/views/datatables/limit',
  'modules/shop.cash-register-retail/views/datatables/loader',
  'modules/shop.cash-register-retail/views/datatables/settingsButton',
  'modules/shop.cash-register-retail/views/datatables/search',

  'modules/shop.cash-register-retail/views/datatables/settingsCollection',
  'modules/shop.cash-register-retail/views/datatables/labelCollection',

  'modules/shop.cash-register-retail/views/datatables/table/layout',

  'modules/shop.cash-register-retail/views/datatables/position',
  'modules/shop.cash-register-retail/views/datatables/pagination',

  // Models
  'modules/shop.cash-register-retail/models/datatables/LimitModel',
  'modules/shop.cash-register-retail/models/datatables/DefaultModel',

  'modules/common/views/swappableWithLoading',
], (
  $, _, Backbone, Template,
  String,
  PresetsView, LimitView, LoaderView, SettingsButtonView, SearchView,
  SettingsDropdownView, LabelCollectionView,
  TableView,
  PositionView, PaginationView,
  LimitModel, DefaultModel,
  Swappable,
) => Backbone.Marionette.LayoutView.extend({
  template: Template,

  className() {
    return `upx-datatable ${this.getTableName()}`;
  },

  /**
         * https://marionettejs.com/docs/v2.4.7/marionette.region.html
         */
  regions: {
    limit: '[data-region="limit"]',
    loader: '[data-region="loader"]',
    settings: '[data-region="settings"]',
    search: '[data-region="search"]',
    settingsSubHeader: '[data-region="settings-sub-header"]',
    labelsSubHeader: '[data-region="labels-sub-header"]',
    table: '[data-region="table"]',
    pageInfo: '[data-region="page-info"]',
    pagination: '[data-region="pagination"]',
    settingsModal: '[data-region="settings-modal"]',
    headerPresets: '[data-region="header-presets"]',
  },

  /**
         * http://backbonejs.org/#View-constructor
         */
  initialize() {
    this.initializeVariables();
    this.setSortingState();

    const self = this;
    // Function initialisation is here because then its not breaking when you have multiple of this view on one page.
    this.debouncedUpdateCollection = _.debounce(() => {
      self._updateCollection();
    }, 10);
  },

  /**
         * used to store data in localStorage
         * @override
         */
  getTableName() {
    return false;
  },

  /**
         * Initializes all the variables of the view
         */
  initializeVariables() {
    this.tableModel = new DefaultModel();
    this.collection = new Backbone.Collection();
    this.limitCollection = new (Backbone.Collection.extend({
      model: LimitModel,
    }))();
    this.settingCollection = new Backbone.Collection();
    this.columnDefinitionCollection = new Backbone.Collection();
    this.presetCollection = new Backbone.Collection();

    this.initializeModel();
    this.initializeCollection();
    this.initializeLimitCollection();
    this.initializeSettingsCollection();
    this.initializeColumnDefinitionCollection();
    this.initializePresetsCollection();

    // Automatic set the ID;
    this.presetCollection.each((model) => {
      model.set(
        'id',
        String.toSlug(model.get('name')),
      );
    });
  },

  /**
         * Loops true the columnDefinitionCollection and the first model with sortName AND sortDir set is being used to set the sorting state.
         */
  setSortingState() {
    let foundSortingModel = false;
    const self = this;
    this.columnDefinitionCollection.each((settingModel) => {
      if (
        !foundSortingModel
                    && settingModel.has('sortName')
                    && settingModel.has('sortDir')
                    && (
                      settingModel.get('sortDir') === 'asc'
                        || settingModel.get('sortDir') === 'desc'
                    )
      ) {
        foundSortingModel = true;
        self.tableModel.set({
          sortName: settingModel.get('sortName'),
          sortDir: settingModel.get('sortDir'),
        });
      }
    });
  },

  /**
         * Used to add limits to this.limitCollection
         * @override
         */
  initializeLimitCollection() {
    this.limitCollection.add({
      value: 10,
      label: 10,
    });
    this.limitCollection.add({
      value: 25,
      label: 25,
    });
    this.limitCollection.add({
      value: 50,
      label: 50,
    });
    this.limitCollection.add({
      value: 100,
      label: 100,
    });
    this.limitCollection.add({
      value: 250,
      label: 250,
    });
    this.limitCollection.add({
      value: 500,
      label: 500,
    });
  },

  /**
         * determents if the pagination controls should be rendered, if not it will shows all rows
         * @returns {boolean}
         * @override
         */
  hasPagination() {
    return true;
  },
  /**
         * determents if there is a searchfield in the table or not.
         * @returns {boolean}
         * @override
         */
  hasSearchField() {
    return true;
  },

  /**
         * Show an print button at the bottom or not.
         * @returns {boolean}
         * @override
         */
  hasPrintButton() {
    return false;
  },

  /**
         * Used to add settings to this.settingCollection
         * @override
         */
  initializeSettingsCollection() {},

  /**
         * Used to add columns to this.columnDefinitionCollection
         * @override
         */
  initializeColumnDefinitionCollection() { throw new Error('ColumnDefinitionCollection is not declared.'); },

  /**
         * This function should add presets to the this.presetCollection.
         * @override
         */
  initializePresetsCollection() {},

  /**
         * Used to set stuff in this.tableModel.
         * @override
         */
  initializeModel() {},

  /**
         * Used to set this.collection to a collection to fetch against
         * @override
         */
  initializeCollection() { },

  onRender() {
    this.renderPresets();
    this.renderHeaderLeftFirst();
    this.renderHeaderLeftSecond();
    this.renderHeaderCenter();
    if (this.hasSearchField()) {
      this.renderHeaderRight();
    }

    this.renderSettingsSubHeader();
    this.renderLabelsSubHeader();

    this.renderTable();

    this.renderFooterLeft();
    this.renderFooterRight();
  },

  // region onRender Function
  renderPresets() {
    const region = this.getRegion('headerPresets');
    const view = new PresetsView({
      collection: this.presetCollection,
    });

    region.show(view);
  },

  renderHeaderLeftFirst() {
    if (this.hasPagination()) {
      const region = this.getRegion('limit');
      const view = new LimitView({
        collection: this.limitCollection,
        tableModel: this.tableModel,
      });

      region.show(view);
    }
  },

  renderHeaderLeftSecond() {
    const region = this.getRegion('loader');
    const view = new LoaderView({
      tableModel: this.tableModel,
    });

    region.show(view);
  },

  renderHeaderCenter() {
    const region = this.getRegion('settings');
    const view = new SettingsButtonView({
      collection: this.settingCollection,
      tableModel: this.tableModel,
    });

    region.show(view);
  },

  renderHeaderRight() {
    const region = this.getRegion('search');
    const view = new SearchView({
      tableModel: this.tableModel,
    });

    region.show(view);
  },

  renderSettingsSubHeader() {
    const region = this.getRegion('settingsSubHeader');
    const view = new SettingsDropdownView({
      collection: this.settingCollection,
      presetCollection: this.presetCollection,
      tableModel: this.tableModel,
    });

    region.show(view);
  },

  renderLabelsSubHeader() {
    const region = this.getRegion('labelsSubHeader');
    const view = new LabelCollectionView({
      collection: this.settingCollection,
    });

    region.show(view);
  },

  renderTable() {
    const region = this.getRegion('table');
    const view = new TableView({
      columnDefinitionCollection: this.columnDefinitionCollection,
      collection: this.collection,
      tableModel: this.tableModel,
      settingCollection: this.settingCollection,
      tableView: this,
    });
    (new Swappable()).proxySwappableEvents(view, this);
    region.show(view);
  },

  renderFooterLeft() {
    if (this.hasPagination()) {
      const region = this.getRegion('pageInfo');
      const view = new PositionView({
        collection: this.collection,
        tableModel: this.tableModel,
      });

      region.show(view);
    }
  },

  renderFooterRight() {
    if (this.hasPagination()) {
      const region = this.getRegion('pagination');
      const view = new PaginationView({
        collection: this.collection,
        tableModel: this.tableModel,
      });

      region.show(view);
    }
  },
  // endregion

  onShow() {
    this.updateCollection();
    this.tableModel.on('change:page', this.updateCollection, this);
    this.tableModel.on('change:limit', this.resetPageAndUpdateCollection, this);
    this.tableModel.on('change:search', this.resetPageAndUpdateCollection, this);
    this.tableModel.on('change:sortName', this.resetPageAndUpdateCollection, this);
    this.tableModel.on('change:sortDir', this.resetPageAndUpdateCollection, this);
    this.tableModel.on('change:settingsShown', this.settingsUpdateCollection, this);
    this.columnDefinitionCollection.on('all', this.saveState, this);
  },

  onDestroy() {
    this.tableModel.off('change:page', this.updateCollection, this);
    this.tableModel.off('change:limit', this.resetPageAndUpdateCollection, this);
    this.tableModel.off('change:search', this.resetPageAndUpdateCollection, this);
    this.tableModel.off('change:sortName', this.resetPageAndUpdateCollection, this);
    this.tableModel.off('change:sortDir', this.resetPageAndUpdateCollection, this);
    this.tableModel.off('change:settingsShown', this.settingsUpdateCollection, this);
    this.columnDefinitionCollection.off('all', this.saveState, this);
  },
  /**
         * Checks if the settings are checked.
         * Then saves the state of the table.
         *
         */
  settingsUpdateCollection() {
    let hasChangedSettings = false;
    this.settingCollection.each((settingModel) => {
      if (settingModel.changedAttributes()) {
        hasChangedSettings = true;
      }
    });
    if (
      !this.tableModel.get('settingsShown')
                && hasChangedSettings
    ) {
      this.resetPageAndUpdateCollection();
    }
  },

  /**
         * Same as updateCollection only it reset the page
         */
  resetPageAndUpdateCollection() {
    this.tableModel.set('page', 0);
    this.updateCollection();
  },

  /**
         * Start updating of the collection with enabling the loader
         */
  updateCollection() {
    this.tableModel.set('loading', true);
    this.debouncedUpdateCollection();
  },

  /**
         * Starts the loader in the table.
         * returns a defferred object that removed the loader when its resolved or rejected
         * @returns {jQuery.Deferred}
         */
  startLoader() {
    const def = new $.Deferred();
    this.tableModel.set('loading', true);

    const self = this;
    def.then(
      () => {
        self.tableModel.set('loading', false);
      },
      () => {
        self.tableModel.set('loading', false);
      },
    );

    return def;
  },

  /**
         * Stops the loader
         */
  stopLoader() {
    this.tableModel.set('loading', false);
  },

  sortByPathFn(sortPath, sortOrder) {
    sortPath = (`${sortPath}`).replace('/', '.');
    return (a, b) => {
      let fa = '';
      if (a.has(sortPath)) {
        fa = `${a.get(sortPath)}`;
      }
      let fb = '';
      if (b.has(sortPath)) {
        fb = `${b.get(sortPath)}`;
      }
      let result;
      if (fa.match(/^[0-9.]+$/) && fb.match(/^[0-9.]+$/)) {
        // both are numeric compare as numbers
        fa = parseFloat(fa);
        fb = parseFloat(fb);
        if (fa !== fb) {
          result = fa > fb ? 1 : -1;
        } else {
          result = 0;
        }
      } else {
        result = fa.localeCompare(fb);
      }
      if (sortOrder === 'desc') {
        result = -result;
      }
      return result;
    };
  },

  sortCollection() {
    const sortPath = this.tableModel.get('sortName');
    const sortOrder = this.tableModel.get('sortDir') || 'asc';
    if (sortPath) {
      this.collection.comparator = this.sortByPathFn(sortPath, sortOrder);
      this.collection.sort();
    }
  },

  /**
         * updates the collection with the collected parameters
         * @private
         */
  _updateCollection() {
    const def = new $.Deferred();
    def.resolve();
    this.stopLoader();
    this.sortCollection();
    return def.promise();
  },

  serializeData() {
    return {
      hasSearchField: this.hasSearchField(),
      hasPrintButton: this.hasPrintButton(),
      hasPagination: this.hasPagination(),
      hasSettings: this.settingCollection.length > 0,
    };
  },

}));
